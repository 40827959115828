// dataGlobalColumnInfoBuilder.js

export function buildDataGlobalColumnInfo(dataGlobal) {
  // If dataGlobal is empty or not provided, return an empty array
  if (!dataGlobal || dataGlobal.length === 0) {
    return [];
  }

  // Prespecified columns to be hidden
  const hiddenColumns = new Set([
    'in_w_control_sample',
    'raw_t1_path',
    'processed_affine_t1_path',
    'processed_smoothed_t1_path',
    'region_gm_path',
    't1_z_map_path',
    't1_w_map_path',
    'region_gm_z_path',
    'region_gm_w_path',
    'valid',
    'scan_date',
    'user',
    'user_id',
    'unique_study',
    'unique_study_id',
    'UMAP_1',
    'UMAP_2',
    'field_strength',
    'race',
    'MMSE',
    'APOE',
    'CDR',
    'autoencoder_pc_3',
    'autoencoder_pc_4',
    'autoencoder_pc_5',
    'autoencoder_pc_6',
    'autoencoder_pc_7',
    'autoencoder_pc_8',
    'autoencoder_pc_9',
    'autoencoder_pc_10',
  ]);

  // Prespecified order of columns
  const columnSequenceOrder = [
    'id',
    'study',
    'participant_id',
    'session_id',
    'age',
    'sex',
    'clinical_diagnosis',
    'handedness',
    'scan_site',
    'scanner_manufacturer',
    'scanner_model',
    'education_years',
    'CDRSB',
    'TIV',
    'IQR',
    'scan_date',
    'autoencoder_pc_1',
    'autoencoder_pc_2',
    'autoencoder_pc_3',
    'autoencoder_pc_4',
    'autoencoder_pc_5',
    'autoencoder_pc_6',
    'autoencoder_pc_7',
    'autoencoder_pc_8',
    'autoencoder_pc_9',
    'autoencoder_pc_10',
    'UMAP_1',
    'UMAP_2',
    'atrophy_mean_z',
    'user',
    'unique_study',
    'in_w_control_sample',
    'race',
    'MMSE',
    'APOE',
    'CDR',
    'field_strength',
    'raw_t1_path',
    'processed_affine_t1_path',
    'processed_smoothed_t1_path',
    'region_gm_path',
    't1_z_map_path',
    't1_w_map_path',
    'region_gm_z_path',
    'region_gm_w_path',
    'valid',
  ];

  // Mapping from column name to sequence number
  const columnSequenceMap = {};
  columnSequenceOrder.forEach((colName, index) => {
    columnSequenceMap[colName] = index + 1; // Start sequence from 1
  });

  // Prespecified categorical and continuous columns
  const categoricalColumnsSet = new Set([
    'clinical_diagnosis',
    'handedness',
    'sex',
    'study',
    'scan_site',
    'scanner_manufacturer',
    'scanner_model',
    'field_strength',
    'race',
    'APOE',
  ]);

  const continuousColumnsSet = new Set([
    'age',
    'education_years',
    'TIV',
    'IQR',
    'CDRSB',
    'MMSE',
    'CDR',
    'UMAP_1',
    'UMAP_2',
    'atrophy_mean_z',
    'autoencoder_pc_1',
    'autoencoder_pc_2',
    'autoencoder_pc_3',
    'autoencoder_pc_4',
    'autoencoder_pc_5',
    'autoencoder_pc_6',
    'autoencoder_pc_7',
    'autoencoder_pc_8',
    'autoencoder_pc_9',
    'autoencoder_pc_10',
  ]);

  // Initialize columnInfo array
  const columnInfo = Object.keys(dataGlobal[0]).map((columnName) => {
    const visible = !hiddenColumns.has(columnName);
    let variableType = 'other';
    let dataType = 'string';
    let type = 'string';
    let valueOptions = [];

    if (columnName === 'id') {
      variableType = 'continuous';
      dataType = 'number';
      type = 'number';
    } else if (categoricalColumnsSet.has(columnName)) {
      variableType = 'categorical';
      dataType = 'string';
      type = 'singleSelect';
      valueOptions = Array.from(new Set(dataGlobal.map((item) => item[columnName])));
    } else if (continuousColumnsSet.has(columnName)) {
      variableType = 'continuous';
      dataType = 'number';
      type = 'number';
    } else {
      // Infer variableType based on data
      const sampleValues = dataGlobal.map((item) => item[columnName]);
      const isNumberColumn = sampleValues.every((value) => {
        if (value === null || value === 'NA') {
          return true;
        }
        const coercedNumber = parseFloat(value);
        return Number.isFinite(coercedNumber);
      });
      if (isNumberColumn) {
        variableType = 'continuous';
        dataType = 'number';
        type = 'number';
      } else {
        variableType = 'categorical';
        dataType = 'string';
        type = 'singleSelect';
        valueOptions = Array.from(new Set(sampleValues));
      }
    }

    const width = columnName === 'id' ? 58 : 75;
    const sequence = columnSequenceMap[columnName] || (Object.keys(columnSequenceMap).length + 1);

    return {
      name: columnName,
      dataType,
      visible,
      variableType,
      width,
      type,
      valueOptions,
      sequence,
    };
  });

  // Sort columnInfo by sequence number
  columnInfo.sort((a, b) => a.sequence - b.sequence);

  return columnInfo;
}