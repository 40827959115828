import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { requestLoginCode } from '../lib/allauth'
import { Navigate } from 'react-router-dom'
import Button from '../components/Button'

export default function RequestLoginCode () {
  const [email, setEmail] = useState('')
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    setResponse({ ...response, fetching: true })
    requestLoginCode(email).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  if (response.content?.status === 401) {
    return <Navigate to='/account/login/code/confirm' />
  }
  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray p-4">
      <div className="w-full max-w-md">
        <h1 className="text-2xl font-bold text-black mb-4">Mail me a sign-in code</h1>
        <p className="text-black mb-4">
          You will receive an email containing a special code for a password-free sign-in.
        </p>

        <FormErrors errors={response.content?.errors} />

        <div className="mb-4">
          <label className="block text-black text-sm font-medium mb-2" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            required
            className="w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green focus:border-transparent"
          />
          <FormErrors param='email' errors={response.content?.errors} />
        </div>
        <Button
          disabled={response.fetching}
          onClick={() => submit()}
          className="w-full bg-green/80 text-white py-1 rounded-md hover:bg-green/90 active:bg-green focus:outline-black disabled:bg-green/70 transition-colors duration-200"
        >
          {response.fetching ? 'Requesting...' : 'Request Code'}
        </Button>
      </div>
    </div>
  )
}