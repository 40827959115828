import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { confirmLoginCode, Flows } from '../lib/allauth'
import { Navigate } from 'react-router-dom'
import Button from '../components/Button'
import { useAuthStatus } from '../auth'

export default function ConfirmLoginCode () {
  const [, authInfo] = useAuthStatus()
  const [code, setCode] = useState('')
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    setResponse({ ...response, fetching: true })
    confirmLoginCode(code).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  if (authInfo.pendingFlow?.id !== Flows.LOGIN_BY_CODE) {
    return <Navigate to='/account/login/code' />
  }
  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray p-4">
      <div className="w-full max-w-md">
        <h1 className="text-2xl font-bold text-black mb-4">Enter Sign-In Code</h1>
        <p className="text-black mb-4">
          The code expires shortly, so please enter it soon.
        </p>

        <FormErrors errors={response.content?.errors} />

        <div className="mb-4">
          <label htmlFor="code" className="block text-black text-sm font-medium mb-2">
            Code
          </label>
          <input
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type='text'
            required
            className="w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green focus:border-transparent"
          />
          <FormErrors param='code' errors={response.content?.errors} />
        </div>
        <Button
          disabled={response.fetching}
          onClick={() => submit()}
          className="w-full bg-green/80 text-white py-1 rounded-md hover:bg-green/90 active:bg-green transition-colors duration-200 focus:outline-black disabled:bg-green/70"
        >
          {response.fetching ? 'Signing In...' : 'Sign In'}
        </Button>
      </div>
    </div>
  )
}