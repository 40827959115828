import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { changePassword } from '../lib/allauth'
import { Navigate } from 'react-router-dom'
import { useUser } from '../auth'
import Button from '../components/Button'

export default function ChangePassword () {
  const hasCurrentPassword = useUser().has_usable_password
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [newPassword2Errors, setNewPassword2Errors] = useState([])

  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    if (newPassword !== newPassword2) {
      setNewPassword2Errors([{ param: 'new_password2', message: 'Password does not match.' }])
      return
    }
    setNewPassword2Errors([])
    setResponse({ ...response, fetching: true })
    changePassword({ current_password: currentPassword, new_password: newPassword }).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }
  if (response.content?.status === 200) {
    // return <Navigate to='/dashboard' />
    return <Navigate to='/' />
  }
  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-semibold mb-4">{hasCurrentPassword ? 'Change Password' : 'Set Password'}</h1>

      <p className="mb-6 text-black">
        {hasCurrentPassword
          ? 'Enter your current password, followed by your new password.'
          : 'You currently have no password set. Enter your (new) password.'}
      </p>

      {hasCurrentPassword && (
        <div className="mb-4">
          <label className="block text-black mb-2">
            Current Password
            <input
              autoComplete='current-password'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              type='password'
              required
              className="mt-1 w-full px-4 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
            />
          </label>
          <FormErrors param='current_password' errors={response.content?.errors} />
        </div>
      )}

      <div className="mb-4">
        <label className="block text-black mb-2">
          New Password
          <input
            autoComplete='new-password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type='password'
            required
            className="mt-1 w-full px-4 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
          />
        </label>
        <FormErrors param='new_password' errors={response.content?.errors} />
      </div>

      <div className="mb-6">
        <label className="block text-black mb-2">
          Confirm New Password
          <input
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
            type='password'
            required
            className="mt-1 w-full px-4 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
          />
        </label>
        <FormErrors param='new_password2' errors={newPassword2Errors} />
        </div>

<Button
  disabled={response.fetching}
  onClick={submit}
  className="w-full bg-green/80 text-white py-1 px-4 rounded-md hover:bg-green/90 active:bg-green disabled:opacity-50"
>
  {hasCurrentPassword ? 'Change Password' : 'Set Password'}
</Button>
</div>
)
}
