import { useRef, useEffect, useState, useCallback } from "react";
import { getCSRFToken } from './lib/django';
import { Niivue, NVImage } from "@niivue/niivue";
import axios from 'axios';
import React from 'react';
import {
  LockClosedIcon,
  LockOpenIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  TrashIcon,
  CheckIcon,
  ChevronDownIcon as ChevronDownIconSolid,
} from '@heroicons/react/24/solid';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'


function IconButton({ onClick, title, children, className }) {
  return (
    <button
      onClick={onClick}
      title={title}
      className={`p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
    >
      {children}
    </button>
  );
}

function MRIViewer({
  selectedData,
  statsModels,
  selectedRegions,
  setSelectedRegions,
  brainRegionInfo,
  setBrainRegionInfo,
}) {
  const canvasRef = useRef(null);
  const nvRef = useRef();
  const containerRef = useRef(null);
  const prevNiiFileListRef = useRef();

  const [niiFileList, setNiiFileList] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 1024, height: 512 });
  const [intensityArray, setIntensityArray] = useState([]);
  const [voxelLabel, setVoxelLabel] = useState('');
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  // State for map adding functionality
  const [query, setQuery] = useState('');
  const [selectedMap, setSelectedMap] = useState(null);

  // List of available maps
  const autoencoderMaps = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    name: `autoencoder_pc_${i + 1}`,
    filename: `image_files/latent_dimension_maps/dimension_${String(i + 1).padStart(4, '0')}_map.nii.gz`,
  }));

  const gradientMaps = Array.from({ length: 12 }, (_, i) => ({
    id: i + 1,
    name: `func_gradient_${i + 1}`,
    filename: `image_files/brown_gradients/pca_grad_disc_${String(i + 1).padStart(3, '0')}.nii.gz`,
  }));

  // Filtered maps based on query
  function getFilteredMaps(query, ...mapArrays) {
    return mapArrays.reduce((acc, maps) => {
      const filtered = query === ''
        ? maps
        : maps.filter((map) =>
            map.name.toLowerCase().includes(query.toLowerCase())
          );
      return acc.concat(filtered);
    }, []);
  }
  
  // const filteredMaps = getFilteredMaps(query, autoencoderMaps, gradientMaps);
  const filteredMaps = getFilteredMaps(query, autoencoderMaps);

  // Load initial files
  useEffect(() => {
    const loadInitialFiles = async () => {
      const initialFiles = [
        {
          filename: 'image_files/mni_icbm152_t1_tal_nlin_asym_09c_brain_1_5_mm.nii.gz',
          opacity: 1,
          colormap: 'gray',
          group: 'atlas',
          displayName: 'MNI152NLin2009cAsym'
        },
        {
          filename: 'image_files/yan_tian_332_atlas_1_5_mm.nii.gz',
          opacity: 0,
          colormap: 'nih',
          group: 'atlas',
          displayName: 'Yan + Tian atlas'
        },
        {
          filename: 'image_files/latent_dimension_maps/dimension_0001_map.nii.gz',
          opacity: 0,
          colormap: 'redyell',
          colormapNegative: 'blue2cyan',
          group: 'featureMap',
          displayName: 'autoencoder_pc_1'
        },
        {
          filename: 'image_files/latent_dimension_maps/dimension_0002_map.nii.gz',
          opacity: 0,
          colormap: 'redyell',
          colormapNegative: 'blue2cyan',
          group: 'featureMap',
          displayName: 'autoencoder_pc_2'
        },
      ];
      const fileInfoPromises = initialFiles.map(file => fileSetup(file));
      const fileInfo = await Promise.all(fileInfoPromises);
      setNiiFileList(fileInfo);
    };

    loadInitialFiles();
  }, []);


  // Show brain region name for current voxel
  const handleIntensityChange = useCallback((data) => {
    setIntensityArray(data.values.map(valueItem => valueItem.value));
    const yanTianEntry = data.values.find(
      item => item.name === 'image_files/yan_tian_332_atlas_1_5_mm.nii.gz'
    );
    const currentVoxLabel = yanTianEntry?.value > 0
      ? `${brainRegionInfo[yanTianEntry.value - 1].hemisphere} ${brainRegionInfo[yanTianEntry.value - 1].region_name}`
      : 'Outside gray matter';
    setVoxelLabel(currentVoxLabel);
  }, [brainRegionInfo]);


  useEffect(() => {
    if (brainRegionInfo) {
  
      if (!nvRef.current) {
        nvRef.current = new Niivue({
          backColor: [0.5, 0.5, 0.5, 0],
          onLocationChange: handleIntensityChange,
          show3Dcrosshair: true,
          isColorbar: true,
          colorbarHeight: 0.05,
          colorbarMargin: 0.02,
          colorbarSpace: 0.01,
          crosshairColor: [1, 0, 0, 1],
        });
        nvRef.current.attachToCanvas(canvasRef.current);
        nvRef.current.setSliceType(nvRef.current.sliceTypeMulti2D);
        nvRef.current.setInterpolation(true);
      }
  
      const prevIds = prevNiiFileListRef.current ? prevNiiFileListRef.current.map(f => f.id) : [];
      const currentIds = niiFileList.map(f => f.id);
  
      const addedVolumes = niiFileList.filter(f => !prevIds.includes(f.id));
      const removedVolumes = prevNiiFileListRef.current ? prevNiiFileListRef.current.filter(f => !currentIds.includes(f.id)) : [];
  
      const volumesChanged = addedVolumes.length > 0 || removedVolumes.length > 0;
      const orderChanged = !volumesChanged && !currentIds.every((id, index) => id === prevIds[index]);
  
      const updateVolumes = async () => {
        // Remove volumes
        for (const file of removedVolumes) {
          const volume = nvRef.current.volumes.find(v => v.name === file.id);
          if (volume) {
            nvRef.current.removeVolume(volume);
          }
        }

        // Add new volumes
        for (const file of addedVolumes) {
          await nvRef.current.addVolume(file); // 'file' is an NVImage instance with correct 'name'
          if (file.colormapNegative) {
            nvRef.current.setColormapNegative(file.id, file.colormapNegative);
          }
        }
  
        // Reorder volumes if necessary
        if (orderChanged) {
          const volumesInDesiredOrder = niiFileList.map(file =>
            nvRef.current.volumes.find(v => v.name === file.id)
          );
          nvRef.current.volumes = volumesInDesiredOrder.filter(v => v); // Ensure no undefined entries
        }
  
        // Update properties of existing volumes
        nvRef.current.volumes.forEach((volume) => {
          const file = niiFileList.find(f => f.id === volume.name);
          if (file) {
            volume.opacity = file.opacity;
            volume.cal_min = file.cal_min;
            volume.cal_max = file.cal_max;
            volume.cal_minNeg = file.cal_minNeg;
            volume.cal_maxNeg = file.cal_maxNeg;
            volume.colorbarVisible = file.opacity > 0;
            volume.colorbarLabel = file.displayName;
          }
        });
  
        nvRef.current.updateGLVolume();
        nvRef.current.drawScene();
  
        prevNiiFileListRef.current = niiFileList;
      };
  
      updateVolumes();
    }
  }, [niiFileList, brainRegionInfo, selectedRegions, handleIntensityChange]);

  // Handle container dimensions change
  useEffect(() => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, []);

  // Fetch presigned URL
  const fetchPresignedUrl = async (filename) => {
    const csrfToken = getCSRFToken();
    try {
      const response = await axios.get(`${apiBaseUrl}/get_presigned_urls/`, {
        params: { object_name: filename },
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      return response.data.url;
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error fetching presigned URL:", error.response.data);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Something else happened
        console.error("Error setting up request:", error.message);
      }
      throw error;
    }
  };

  // fileSetup 
  const fileSetup = async ({
    filename,
    opacity = 1,
    colormap = 'gray',
    colormapNegative = '',
    group = '',
    displayName = ''
  }) => {
    try {
      const url = await fetchPresignedUrl(filename);
      if (!url) throw new Error(`No URL returned for filename: ${filename}`);

      // Load the image using NVImage.loadFromUrl
      const image = await NVImage.loadFromUrl({ url });

      displayName = displayName || filename.split('/').pop();

      const maxAbsValue = Math.max(Math.abs(image.global_min), Math.abs(image.global_max));
      const rangePadding = 0.1 * maxAbsValue;
      const robust_min = (-maxAbsValue - rangePadding).toFixed(1);
      const robust_max = (maxAbsValue + rangePadding).toFixed(1);
      const robust_stepsize = ((robust_max - robust_min) / 50).toFixed(5);

      // Initialize sliders at 1% of max/min values
      const positiveStart = parseFloat(robust_max) * 0.01;
      const negativeStart = parseFloat(robust_min) * 0.01;

      // Set additional properties on the image object
      image.id = filename; // Use filename or another unique identifier
      image.name = filename; // Important: set name to match id
      image.opacity = opacity;
      image.colormap = colormap;
      image.colormapNegative = colormapNegative;
      image.intensity = 0;
      image.robust_min = robust_min;
      image.robust_max = robust_max;
      image.robust_stepsize = robust_stepsize;
      image.group = group;
      image.displayName = displayName;
      image.cal_min = positiveStart;
      image.cal_max = parseFloat(robust_max);
      image.cal_minNeg = parseFloat(robust_min);
      image.cal_maxNeg = negativeStart;
      image.slidersLocked = true;

      return image; // Return the NVImage instance with added properties
    } catch (error) {
      console.error("Error in fileSetup:", error);
      throw error;
    }
  };

  // Reference to keep track of the latest niiFileList
  const niiFileListRef = useRef(niiFileList);
  useEffect(() => {
    niiFileListRef.current = niiFileList;
  }, [niiFileList]);

  // Function to add a new map
  const addMap = async (map) => {
    if (!map) return; // If map is null or undefined, do nothing

    // Check if map is already loaded
    const mapAlreadyLoaded = niiFileListRef.current.some(
      (file) => file.displayName === map.name
    );
    if (!mapAlreadyLoaded) {
      try {
        const newFile = await fileSetup({
          filename: map.filename,
          opacity: 1,
          colormap: 'redyell',
          colormapNegative: 'blue2cyan',
          group: 'featureMap',
          displayName: map.name,
        });
        setNiiFileList((prevState) => [...prevState, newFile]);
      } catch (error) {
        console.error('Error adding map:', error);
      }
    }
  };

  // Add individual subject images from selectedData
  useEffect(() => {
    if (selectedData && selectedData.processed_affine_t1_path) {
      const matchResult = selectedData.processed_affine_t1_path.match(/(sub-[A-Za-z0-9]+)/);
      if (matchResult) {
        const filesToAddPromises = [
          {
            filename: selectedData.processed_affine_t1_path.split('radiata-data/')[1],
            opacity: 1,
            colormap: 'gray',
            group: 'subjectData',
            displayName: 'Subject T1'
          },
          {
            filename: selectedData.t1_z_map_path.split('radiata-data/')[1],
            opacity: 0,
            colormap: 'redyell',
            colormapNegative: 'blue2cyan',
            group: 'subjectData',
            displayName: 'Atrophy Z-map'
          }
        ].map(file => fileSetup(file));

        Promise.all(filesToAddPromises).then(filesToAdd => {
          setNiiFileList(prevState => {
            // Remove existing subjectData files
            const filteredPrevState = prevState.filter(image => image.group !== 'subjectData');
            // Separate statsModels files to keep them on top
            const statsModelsFiles = filteredPrevState.filter(image => image.group.startsWith('model_'));
            const otherFiles = filteredPrevState.filter(image => !image.group.startsWith('model_'));
            // Reconstruct niiFileList with the desired order
            return [...otherFiles, ...filesToAdd, ...statsModelsFiles];
          });
        });
      }
    }
  }, [selectedData]);

  
  // Add results images from statsModels
  useEffect(() => {
    if (statsModels.length > 0) {
      const currentModel = statsModels[0];

      const filesToAddPromises = currentModel.features_structure_files.map(file =>
        fileSetup({
          filename: file,
          opacity: 1,
          colormap: "redyell",
          colormapNegative: "blue2cyan",
          group: `model_${currentModel.id}`,
          modelNumber: statsModels.length,
          displayName: file,
        })
      );

      Promise.all(filesToAddPromises).then(filesToAdd => {
        setNiiFileList(prevState => {
          // Remove existing statsModels files
          const filteredPrevState = prevState.filter(image => !image.group.startsWith('model_'));
          // Add new statsModels files at the end to keep them on top
          return [...filteredPrevState, ...filesToAdd];
        });
      }).catch(error => {
        console.error("Error setting up files from statsModels:", error);
      });      
    }
  }, [statsModels]);


  // Toggle file visibility
  const toggleFileVisibility = (index) => {
    setNiiFileList(prevState => {
      const newState = [...prevState];
      newState[index].opacity = newState[index].opacity === 1 ? 0 : 1;
      return newState;
    });
  };  

  // Remove file
  const removeFile = (index) => {
    setNiiFileList(prevState => prevState.filter((_, idx) => idx !== index));
  };

  // Move image up
  const moveImageUp = (index) => {
    if (index > 0) {
      setNiiFileList(prevState => {
        const newState = [...prevState];
        [newState[index - 1], newState[index]] = [newState[index], newState[index - 1]];
        return newState;
      });
    }
  };

  // Move image down
  const moveImageDown = (index) => {
    if (index < niiFileList.length - 1) {
      setNiiFileList(prevState => {
        const newState = [...prevState];
        [newState[index], newState[index + 1]] = [newState[index + 1], newState[index]];
        return newState;
      });
    }
  };

  // Go to brain region
  useEffect(() => {
    if (
      (selectedRegions && selectedRegions.length === 0) || 
      (brainRegionInfo && brainRegionInfo.length === 0)
    ) return;
    
    // Get the world coordinates (in millimeters) of the selected region
    let XYZ = [
      brainRegionInfo[selectedRegions[0]].x_cog_mm,
      brainRegionInfo[selectedRegions[0]].y_cog_mm,
      brainRegionInfo[selectedRegions[0]].z_cog_mm
    ];

    nvRef.current.scene.crosshairPos = nvRef.current.mm2frac(XYZ);
    nvRef.current.updateGLVolume();
    nvRef.current.drawScene();
    setVoxelLabel(brainRegionInfo[selectedRegions[0]].region_name);
    
  }, [selectedRegions, brainRegionInfo]);


  return (
    <div>
      <div className="font-bold m-0 text-2xl p-3">Brain Viewer</div>
      <div className="flex">
        <div className="flex-1" ref={containerRef}>
          <canvas ref={canvasRef} width={dimensions.width} height={dimensions.height} />
        </div>
      </div>
      <div className="w-full">
        <div id="region_info" className="text-left">
          <b>Selected Region: </b>{voxelLabel}<br /><br />
        </div>
        {/* Flex container for "Image List" and Combobox */}
        <div className="flex items-center justify-between mb-2">
          <b>Image List</b>
          {/* Map Adder Combobox */}
          <div className="relative w-52">
          <Combobox
            value={selectedMap}
            onChange={(value) => {
              if (value) {
                addMap(value);
              }
              setSelectedMap(null); // Reset selection after adding or when value is null
            }}
            onClose={() => setQuery('')}
          >
              <div className="relative">
                <ComboboxInput
                  className="w-full border py-1.5 pr-8 pl-3 text-sm"
                  displayValue={(map) => map?.name || ''}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder="Add map..."
                />
                <ComboboxButton className="absolute inset-y-0 right-0 px-2.5">
                  <ChevronDownIconSolid className="h-5 w-5 text-gray-500" />
                </ComboboxButton>
              </div>

              {filteredMaps.length > 0 && (
                <ComboboxOptions
                  className="absolute z-10 mt-1 max-h-60 w-auto max-w-[300px] overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm right-0 left-auto"
                >
                  {filteredMaps.map((map) => (
                    <ComboboxOption
                      key={map.id}
                      value={map}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {map.name}
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-blue-600'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}
            </Combobox>
          </div>
        </div>
        {/* Image List Table */}
        <div id="file_list_struc">
          <table className="table-fixed w-full border border-black border-collapse">
            <thead>
              <tr>
                <th className="w-1/2 border border-black">Filename</th>
                <th className="w-[20%] border border-black">Value</th>
                <th className="w-[25%] border border-black">Controls</th>
              </tr>
            </thead>
            <tbody>
              {niiFileList.slice().reverse().map((file, idx) => {
                const index = niiFileList.length - 1 - idx;
                const intensity = intensityArray[index] || 0;
  
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        onClick={() => toggleFileVisibility(index)}
                        className={`cursor-pointer ${file.opacity ? 'opacity-100' : 'opacity-50'} break-words border border-black`}
                      >
                        {(file.displayName).split('/').pop()}
                      </td>
                      <td>
                        {intensity.toFixed(2)}
                      </td>
                      <td rowSpan="2" className="align-middle text-center border border-black">
                        {/* Control Buttons */}
                        <IconButton
                          onClick={() => {
                            setNiiFileList(prevState => {
                              const newState = [...prevState];
                              newState[index].slidersLocked = !newState[index].slidersLocked;
                              return newState;
                            });
                          }}
                          title="Link/unlink +/- thresholds"
                        >
                          {file.slidersLocked ? (
                            <LockClosedIcon className="h-5 w-5 text-gray-700" />
                          ) : (
                            <LockOpenIcon className="h-5 w-5 text-gray-700" />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => moveImageDown(index)}
                          title="Move image up a layer"
                        >
                          <ChevronUpIcon className="h-5 w-5 text-gray-700" />
                        </IconButton>
                        <IconButton
                          onClick={() => moveImageUp(index)}
                          title="Move image down a layer"
                        >
                          <ChevronDownIcon className="h-5 w-5 text-gray-700" />
                        </IconButton>
                        <IconButton
                          onClick={() => removeFile(index)}
                          title="Remove image"
                          className="hover:bg-red-200 focus:ring-red-500"
                        >
                          <TrashIcon className="h-5 w-5 text-red-600" />
                        </IconButton>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        {/* Slider Controls */}
                        <div className="flex items-center">
                          {parseFloat(file.robust_min) < 0 && parseFloat(file.robust_max) > 0 ? (
                            <div className="flex flex-1">
                              <div className="flex flex-1 items-center mr-1.5">
                                <span className="mr-1 px-1">-</span>
                                <input
                                  type="range"
                                  min={parseFloat(file.robust_min)}
                                  max={0}
                                  value={file.cal_maxNeg}
                                  step={file.robust_stepsize}
                                  className="w-full"
                                  onChange={(e) => {
                                    const newCalMaxNeg = parseFloat(e.target.value);
                                    setNiiFileList(prevState => {
                                      const newState = [...prevState];
                                      const f = newState[index];
                                      f.cal_maxNeg = newCalMaxNeg;
                                      if (f.slidersLocked) {
                                        f.cal_min = -newCalMaxNeg;
                                      }
                                      return newState;
                                    });
                                  }}
                                />
                              </div>
                              <div className="flex flex-1 items-center ml-1.5">
                                <input
                                  type="range"
                                  min={0}
                                  max={parseFloat(file.robust_max)}
                                  value={file.cal_min}
                                  step={file.robust_stepsize}
                                  className="w-full"
                                  onChange={(e) => {
                                    const newCalMin = parseFloat(e.target.value);
                                    setNiiFileList(prevState => {
                                      const newState = [...prevState];
                                      const f = newState[index];
                                      f.cal_min = newCalMin;
                                      if (f.slidersLocked) {
                                        f.cal_maxNeg = -newCalMin;
                                      }
                                      return newState;
                                    });                                    
                                  }}
                                />
                                <span className="ml-1 px-1">+</span>
                              </div>
                            </div>
                          ) : (
                            <div className="flex-1">
                              <input
                                type="range"
                                min={parseFloat(file.robust_min)}
                                max={parseFloat(file.robust_max)}
                                value={file.cal_min}
                                step={file.robust_stepsize}
                                className="w-full"
                                onClick={() => {
                                  setNiiFileList(prevState => {
                                    const newState = [...prevState];
                                    newState[index].slidersLocked = !newState[index].slidersLocked;
                                    return newState;
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex justify-between mt-1 px-1">
                          <span>Min: {file.robust_min}</span>
                          <span>Max: {file.robust_max}</span>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );  
}

export default MRIViewer;
