import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { getPasswordReset, resetPassword } from '../lib/allauth'
import { Navigate, Link, useLoaderData } from 'react-router-dom'
import Button from '../components/Button'

export async function loader ({ params }) {
  const key = params.key
  const resp = await getPasswordReset(key)
  return { key, keyResponse: resp }
}

export default function ResetPassword () {
  const { key, keyResponse } = useLoaderData()

  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [password2Errors, setPassword2Errors] = useState([])

  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    if (password2 !== password1) {
      setPassword2Errors([{ param: 'password2', message: 'Password does not match.' }])
      return
    }
    setPassword2Errors([])
    setResponse({ ...response, fetching: true })
    resetPassword({ key, password: password1 }).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  if ([200, 401].includes(response.content?.status)) {
    return <Navigate to='/account/login' />
  }

  let body
  if (keyResponse.status !== 200) {
    body = <FormErrors param='key' errors={keyResponse.errors} />
  } else if (response.content?.error?.detail?.key) {
    body = <FormErrors param='key' errors={response.content?.errors} />
  } else {
    body = (
      <>
        <div className="mb-4">
          <label className="block text-black mb-1" htmlFor="password1">
            New Password
          </label>
          <input
            id="password1"
            autoComplete='new-password'
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            type='password'
            required
            className="w-full px-3 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
          />
          <FormErrors param='password' errors={response.content?.errors} />
        </div>
        <div className="mb-4">
          <label className="block text-black mb-1" htmlFor="password2">
            Confirm New Password
          </label>
          <input
            id="password2"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            type='password'
            required
            className="w-full px-3 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
          />
          <FormErrors param='password2' errors={password2Errors} />
        </div>

        <Button
          disabled={response.fetching}
          className="w-full bg-green/90 text-white py-1 px-4 rounded-md hover:bg-green focus:outline-black disabled:bg-green/70"
          onClick={() => submit()}
        >
          {response.fetching ? 'Resetting Password...' : 'Reset Password'}
        </Button>
      </>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray p-4 min-h-screen">
      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-4 text-center">Reset Password</h1>
        <p className="mb-4 text-center">
          Remember your password? <Link to='/account/login' className="text-teal-green/80 hover:text-teal-green">Back to log in</Link>
        </p>
        {body}
      </div>
    </div>
  )
}