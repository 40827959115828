
export default function VerificationEmailSent() {
  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-black">Confirm Email Address</h1>
        <p className="mt-4 text-black">
          Please confirm your email address by clicking the link we sent to your inbox.
        </p>
        <button className="mt-6 bg-green/90 text-white px-4 py-1 rounded-md hover:bg-green transition-colors duration-200 focus:outline-black disabled:bg-green/70">
          Resend Email
        </button>
      </div>
    </div>
  );
}