import React, { useState, useRef } from 'react';
import axios from 'axios';
import { getCSRFToken } from './lib/django';



const UploadForm = () => {
    const [files, setFiles] = useState([]);
    const [showFileList, setShowFileList] = useState(false);
    const [showFileInput, setShowFileInput] = useState(true); // Controls file input visibility
    const [isValidated, setIsValidated] = useState(false);
    const inputRef = useRef(null);
    const csrfToken = getCSRFToken();
    const [totalProgress, setTotalProgress] = useState(0); // State for overall progress
    const [totalBytesUploaded, setTotalBytesUploaded] = useState(0);
    const [totalBytesToUpload, setTotalBytesToUpload] = useState(0);
    const [studyName, setStudyName] = useState('');
    const [makePublic, setMakePublic] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadId, setUploadId] = useState('');
    const apiBaseUrl=process.env.REACT_APP_API_URL;


    const getContentType = (fileName) => {
        if (fileName.toLowerCase().endsWith('.nii.gz')) {
          return 'application/gzip';
        } else if (fileName.toLowerCase().endsWith('.json')) {
          return 'application/json';
        } else if (fileName.toLowerCase().endsWith('.tsv')) {
          return 'text/tab-separated-values';
        }
        return 'application/octet-stream'; // Default MIME type
      };
    
      const allowedExtensions = ['.nii.gz', '.json', '.tsv', '.nii'];
    

  const isAllowedExtension = (fileName) => {
    return allowedExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  };

  const handleFileChange = (event) => {
    const allFiles = Array.from(event.target.files);
    const invalidFiles = [];
    const selectedFiles = allFiles
      .filter((file) => {
        const relativePath = file.webkitRelativePath || file.name;
        const pathComponents = relativePath.split('/');

        // Exclude files where any part of the path starts with a '.'
        if (pathComponents.some((component) => component.startsWith('.'))) {
          return false;
        }

        // Check if the file has an allowed extension
        if (isAllowedExtension(file.name)) {
          return true;
        } else {
          invalidFiles.push(relativePath);
          return false;
        }
      })
      .map((file) => ({
        id: Date.now() + Math.random(), // Unique identifier
        file,
        relativePath: file.webkitRelativePath || file.name,
        progress: 0, // Initialize progress to 0
      }));

    if (invalidFiles.length > 0) {
      alert(`The following files are not allowed and will be ignored:\n${invalidFiles.join('\n')}`);
    }

    if (!selectedFiles.length) {
      alert('No valid files selected. Please choose files with extensions: .nii.gz, .json, .tsv');
      return;
    }

    const bytesToUpload = selectedFiles.reduce((acc, fileObj) => acc + fileObj.file.size, 0);
    setTotalBytesToUpload(bytesToUpload);
    const filesWithProgress = selectedFiles.map((fileObj) => ({
      ...fileObj,
      uploadedBytes: 0,
      progress: 0,
    }));

    setFiles(filesWithProgress);
    setIsValidated(false); // Reset validation when files change
    setTotalProgress(0); // Reset overall progress
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!files.length) return;

    if (!studyName.trim()) {
        alert('Please provide a study name.');
        return;
      }

    try {
      // Prepare data for requesting presigned URLs
      const fileInfos = files.map(({ file, relativePath }) => ({
        relativePath,
        contentType: getContentType(file.name),
      }));

      // Determine the base directory from the first file's relative path
      const baseDirectory = files[0].relativePath.split('/')[0]; // This should be '{study_name}'

      // Define the relative path for 'upload_complete.txt'
      const markerRelativePath = `${baseDirectory}/upload_complete.json`;

      // Add marker file info
      const markerFileInfo = {
        relativePath: markerRelativePath, // Use the stored relative path
        contentType: 'application/json',
      };
      fileInfos.push(markerFileInfo);

      // Request presigned URLs, including for 'upload_complete.txt'
      const response = await axios.post(
        `${apiBaseUrl}/generate_presigned_urls/`,
        { 
            files: fileInfos,
            studyName,
            makePublic
         },
        {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Ensure cookies are sent
        }
      );

      const { presignedUrls, uploadId, studyId } = response.data;
      setUploadId(uploadId);
      const assignedStudyId = studyId;

      // Retrieve the presigned URL for 'upload_complete.txt' using the correct relative path
      const markerUrlObj = presignedUrls.find(
        (obj) => obj.relativePath === markerRelativePath
      );
      // Remove the marker file from the list of files to upload initially
      const presignedUrlsWithoutMarker = presignedUrls.filter(
        (obj) => obj.relativePath !== markerRelativePath
      );

      // Upload each file using the presigned URL with progress tracking
      const uploadPromises = presignedUrlsWithoutMarker.map(async ({ relativePath, url, key }) => {
        const fileObj = files.find((f) => f.relativePath === relativePath);
        if (fileObj) {
          try {
            await axios.put(url, fileObj.file, {
              headers: {
                'Content-Type': getContentType(fileObj.file.name),
              },
              onUploadProgress: (progressEvent) => {
                const { loaded } = progressEvent;
                const totalFileSize = fileObj.file.size;

                // Update individual file progress and uploaded bytes, and compute totalUploadedBytes
                setFiles((prevFiles) => {
                  const updatedFiles = prevFiles.map((f) => {
                    if (f.id === fileObj.id) {
                      const progress = Math.round((loaded * 100) / totalFileSize);
                      return {
                        ...f,
                        progress,
                        uploadedBytes: loaded,
                      };
                    }
                    return f;
                  });

                  // Compute totalUploadedBytes
                  const totalUploadedBytes = updatedFiles.reduce(
                    (acc, f) => acc + (f.uploadedBytes || 0),
                    0
                  );

                  // Compute overall progress
                  const overallProgress = Math.round(
                    (totalUploadedBytes * 100) / totalBytesToUpload
                  );

                  setTotalProgress(overallProgress);

                  return updatedFiles;
                });
              },
            });

            // Notify the server about the successful upload
            await axios.post(
              `${apiBaseUrl}/log_file_upload/`,
              { key, relativePath, studyName, makePublic, uploadId },
              {
                headers: {
                  'X-CSRFToken': csrfToken,
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
              }
            );
            
            if (!uploadId) {
                throw new Error('Upload ID is not available.');
              }

          } catch (uploadError) {
            console.error(`Error uploading ${relativePath}:`, uploadError);
            throw uploadError; // This will be caught by the outer try-catch
          }
        }
      });

      // Wait for all file uploads to complete
      await Promise.all(uploadPromises);

      // **Now upload 'upload_complete.txt'**
      try {
        if (!markerUrlObj) {
          throw new Error('Presigned URL for upload_complete.json not found.');
        }
        const { url, key } = markerUrlObj;

        // **Generate 'upload_complete.txt' content using JavaScript**
        //const markerFileContent = 'Upload complete';
        const markerFileContent = JSON.stringify({
            study: studyName,
            unique_study_id: assignedStudyId,
          });
        //const markerFile = new Blob([markerFileContent], { type: 'text/plain' });
        const markerFile = new Blob([markerFileContent], { type: 'application/json' });

        await axios.put(url, markerFile, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
        
          // Notify the server about the successful upload of the marker file
          await axios.post(
            `${apiBaseUrl}/log_file_upload/`,
            {
              key,
              relativePath: markerRelativePath,
              studyName,
              makePublic,
              uploadId,
              studyId: assignedStudyId, // Include studyId here if needed on the backend
            },
            {
              headers: {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          );
        
          // All uploads completed successfully
          alert('Files uploaded successfully.');
        } catch (markerError) {
          console.error('Error uploading upload_complete.json:', markerError);
          alert('An error occurred while uploading upload_complete.json.');
        }

      // Clear the files from state
      setFiles([]);
      setShowFileInput(true); // Hide file input after upload

      // Reset the file input's value
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setTotalProgress(0);
    } catch (error) {
        console.error('Upload error:', error);
  
        // Check if the error is due to a public study name conflict
        if (error.response && error.response.status === 400 && error.response.data.error) {
          // Set the error message in state
          setErrorMessage(error.response.data.error);
  
          // Optionally, set focus back to the study name input field
          document.getElementById('study-name').focus();
        } else {
          setErrorMessage('An error occurred during upload.');
        }
      }
    };

    const checkStudyNameAvailability = async (name, isPublic) => {
        if (!name.trim()) {
          setErrorMessage('');
          return;
        }
    
        if (isPublic) {
          try {
            const response = await axios.get(`${apiBaseUrl}/check_study_name/`, {
              params: { studyName: name.trim() },
              headers: {
                'X-CSRFToken': csrfToken,
              },
              withCredentials: true,
            });
    
            if (response.data.exists) {
              setErrorMessage('A public study with this name already exists. Please choose a different name.');
            } else {
              setErrorMessage('');
            }
          } catch (error) {
            console.error('Error checking study name availability:', error);
            setErrorMessage('Unable to check study name availability.');
          }
        } else {
          // For private studies, clear any error messages
          setErrorMessage('');
        }
      };
  

  const handleClearSelection = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setFiles([]);
    setShowFileInput(true); // Hide file input when clearing selection
    setIsValidated(false); // Reset validation
  };

  const toggleFileList = () => {
    setShowFileList((prevShowFileList) => !prevShowFileList);
  };

  // Handler to remove a specific file
  const handleRemoveFile = (id) => {
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);

    // Optionally, reset validation if no files are left
    if (updatedFiles.length === 0) {
      setShowFileInput(true);
      setIsValidated(false);
    }
  };

  // Function to open BIDS Validator
  const handleValidateClick = () => {
    window.open('https://bids-standard.github.io/bids-validator/', '_blank');
    setShowFileInput(true); // Show file input after validation link is clicked
  };

return (
    <form
    onSubmit={handleSubmit}
    className="ma x-w-xl w-full mx-auto p-6 mt-10 bg-white rounded-lg shadow-md"
    >
    {/* Section: Validate Data Structure */}
    <div className="mb-6 text-center">    
        <h2 className="text-2xl font-semibold text-black mb-4">
        Upload Your Study Data
        </h2>
        <button
        type="button"
        onClick={handleValidateClick}
        className="bg-green/80 text-white px-6 py-1 rounded-md font-medium hover:bg-green/90 active:bg-green disabled:opacity-50"
        >
        Validate Data Structure
        </button>
        <p className="mt-2 text-black">
        Ensure your data folder structure complies with <a href="https://bids.neuroimaging.io/" className="text-blue-500 underline" target="blank">BIDS standards</a> before uploading. For help with BIDS conversion, you can use <a href="https://brainlife.io/ezbids/" className="text-blue-500 underline" target="blank">ezBIDS</a>.
        </p>
    </div>

    {/* Conditional: Show File Input */}
    {showFileInput && (
        <>
        {files.length === 0 ? (
            // State: No files selected
            <div className="mb-6 mx-auto max-w-xl text-left">
            <label
                htmlFor="file-input"
                className="block text-sm font-medium text-black mb-2 "
            >
                Choose Files
            </label>
            <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                multiple
                webkitdirectory=""
                directory=""
                mozdirectory=""
                accept=".nii,.nii.gz,.tsv,.json"
                className="block w-full text-sm text-black cursor-pointer
                file:mr-4 file:py-1 file:px-4
                file:border-0
                file:rounded-md
                file:bg-green/80 file:text-white
                hover:file:bg-green/90 active:file:bg-green"
            />
            <p className="mt-2 text-black">
                Select a directory containing your study files.
            </p>
            </div>
        ) : (
            // State: Files selected
            <>
            {/* Study Name Input */}
            <div className="mb-4">
                <label
                htmlFor="study-name"
                className="block text-sm font-medium text-black mb-1 mx-auto max-w-xl text-left"
                >
                Study Name <span className="text-red-500">*</span>
                </label>
                <input
                type="text"
                id="study-name"
                value={studyName}
                onChange={(e) => {
                    const name = e.target.value;
                    setStudyName(name);
                    checkStudyNameAvailability(name, makePublic);
                }}
                required
                className="mx-auto max-w-xl text-left mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm
                    focus:outline-none focus:ring-teal-green focus:border-teal-green text-sm"
                />
                {errorMessage && (
                <div className="text-red-500 text-sm mt-1 mx-auto max-w-xl text-left">
                    {errorMessage}
                </div>
                )}
            </div>

            {/* Make Study Public Checkbox */}
            <div className="mb-4 mx-auto max-w-xl text-left">
                <label className="inline-flex mx-auto max-w-xl text-left items-center">
                <input
                    type="checkbox"
                    checked={makePublic}
                    onChange={(e) => {
                    const isPublic = e.target.checked;
                    setMakePublic(isPublic);
                    checkStudyNameAvailability(studyName, isPublic);
                    }}
                    className="form-checkbox h-5 w-5 text-teal-green"
                />
                <span className="ml-2 text-black">
                    Make Study Public
                </span>
                </label>
            </div>

            {/* File List Toggle */}
            <div className="mb-4 mx-auto max-w-xl text-left py-1">
                <h3
                onClick={toggleFileList}
                className="cursor-pointer text-lg font-medium text-teal-green hover:underline mx-auto max-w-xl text-left"
                >
                {showFileList ? 'Hide' : 'Show'} Selected Files
                </h3>
                {showFileList && (
                <ul className="mt-2 max-h-40 overflow-y-auto border border-gray-200 rounded-md p-2 bg-gray-50 mx-auto max-w-xl text-left">
                    {files.map(({ id, relativePath, progress }) => (
                    <li key={id} className="mb-2 mx-auto max-w-xl text-left">
                        <div className="flex justify-between items-center">
                        <span className="text-sm text-black">
                            {relativePath}
                        </span>
                        <button
                            type="button"
                            className="ml-4 text-red-500 hover:text-red-700 focus:outline-none "
                            onClick={() => handleRemoveFile(id)}
                        >
                            Remove
                        </button>
                        </div>
                        {progress > 0 && (
                        <div className="w-full bg-gray-200 rounded-full h-2 mt-1">
                            <div
                            className="bg-teal-green h-2 rounded-full"
                            style={{ width: `${progress}%` }}
                            ></div>
                        </div>
                        )}
                    </li>
                    ))}
                </ul>
                )}
            </div>

            {/* Actions */}
            <div className="mb-6 flex flex-col sm:flex-row sm:space-x-2 mx-auto max-w-xl text-left py-1">
                <div className="flex-1 mb-2 sm:mb-0">
                <label
                    htmlFor="file-input-update"
                    className="text-sm text-black mb-1 mx-auto max-w-xl text-left"
                >
                    Update Files
                </label>
                <input
                    id="file-input-update"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    webkitdirectory=""
                    directory=""
                    mozdirectory=""
                    accept=".nii,.nii.gz,.tsv,.json"
                    className="block w-full text-sm text-black cursor-pointer
                    file:mr-4 file:py-1 file:px-4
                    file:border-0
                    file:rounded-md
                    file:bg-green/80 file:text-white
                    hover:file:bg-green/90 active:file:bg-green"
                />
                </div>
                <div className="flex mx-auto max-w-xl ">
                <button
                    type="submit"
                    className="bg-green/80 text-white px-6 py-1 rounded-md hover:bg-green/90 active:bg-green disabled:opacity-50"
                >
                    Upload Files
                </button>
                </div>
                <div className="flex mx-auto max-w-xl">
                <button
                    onClick={handleClearSelection}
                    className="bg-gray-600 text-white px-6 py-1 rounded-md hover:bg-gray-700 active:bg-gray-800 transition-colors duration-200"
                >
                    Clear Selection
                </button>
                </div>
            </div>
            </>
        )}
        </>
    )}

    {/* Total Progress Bar */}
    {totalProgress > 0 && (
        <div className="w-full bg-gray-200 rounded-full h-6 mt-4">
        <div
            className="bg-green h-6 rounded-full text-center text-white text-sm font-medium"
            style={{ width: `${totalProgress}%` }}
        >
            {totalProgress}%
        </div>
        </div>
    )}

  <div className="my-4 border-t border-gray-300 max-w-4xl mx-auto"></div>
  <div className="mb-4 mx-auto max-w-4xl text-left">
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-4 text-center">Instructions for Preparing Your BIDS Dataset</h2>

      <h3 className="text-lg font-semibold mb-2">Directory Structure</h3>

      <h4 className="text-base font-medium mb-1">Without Sessions</h4>
      <pre className="bg-gray-100 p-2 rounded mb-4">
        <code>
    {`<dataset_name>/
    ├── participants.tsv
    ├── sub-<participant_label>/
        ├── anat/
        │   └── sub-<participant_label>_T1w.nii.gz
        └── func/
            └── sub-<participant_label>_task-rest_bold.nii.gz`}
        </code>
      </pre>

      <h4 className="text-base font-medium mb-1">With Sessions</h4>
      <pre className="bg-gray-100 p-2 rounded mb-4">
        <code>
    {`<dataset_name>/
    ├── participants.tsv
    ├── sub-<participant_label>/
        ├── sub-<participant_label>_sessions.tsv
        ├── ses-<session_label>/
            ├── anat/
            │   └── sub-<participant_label>_ses-<session_label>_T1w.nii.gz
            └── func/
                └── sub-<participant_label>_ses-<session_label>_task-rest_bold.nii.gz`}
        </code>
      </pre>

      <h3 className="text-lg font-semibold mb-2">Required Files</h3>

      <h4 className="text-base font-medium mb-1">participants.tsv</h4>
      <p className="mb-2">This file must be placed at the root of your dataset.</p>
      <p className="mb-2"><strong>Required Fields:</strong> <code>participant_id</code></p>
      <p className="mb-2"><strong>Optional Fields:</strong> <code>age</code>, <code>sex</code> (<em>female</em>/<em>male</em>), <code>handedness</code> (<em>left</em>/<em>right</em>/<em>ambidextrous</em>), <code>education_years</code>, <code>clinical_diagnosis</code>, <code>scan_site</code>, <code>scanner_manufacturer</code>, <code>scanner_model</code>, <code>field_strength</code>, <code>race</code>, <code>MMSE</code>, <code>CDRSB</code></p>

      <p className="mb-2 font-semibold">Example participants.tsv:</p>
      <pre className="bg-gray-100 p-2 rounded mb-4">
        <code>
    {`participant_id	age	sex	handedness
    sub-01	        29	female	right
    sub-02	        34	male	left`}
        </code>
      </pre>

      <h3 className="text-lg font-semibold mb-2">Optional Files</h3>
      <h4 className="text-base font-medium mb-1">{`sub-<participant_label>_sessions.tsv`}</h4>
      <p className="mb-2">If your dataset includes sessions, it is optional but not required to place a <code>sub-<span>{`<participant_label>`}</span>_sessions.tsv</code> file inside each participant's directory.</p>
      <p className="mb-2"><strong>Required Fields:</strong> <code>session_id</code></p>
      <p className="mb-2"><strong>Optional Fields:</strong> <code>age</code>, <code>sex</code>, <code>handedness</code>, <code>education_years</code>, <code>clinical_diagnosis</code>, <code>scan_site</code>, <code>scanner_manufacturer</code>, <code>scanner_model</code>, <code>field_strength</code>, <code>race</code>, <code>MMSE</code>, <code>CDRSB</code></p>

      <p className="mb-2 font-semibold">Example sub-01_sessions.tsv:</p>
      <pre className="bg-gray-100 p-2 rounded mb-4">
        <code>
    {`session_id	age	scan_date	scanner_model
    ses-01	        29	2021-06-01	TrioTim
    ses-02	        30	2022-06-01	Prisma`}
        </code>
      </pre>

      <h3 className="text-lg font-semibold mb-2">Important Notes</h3>
      <ul className="list-disc list-inside mb-4">
        <li>The <strong>participants.tsv</strong> file is <strong>mandatory</strong>.</li>
        <li>The <strong>sessions.tsv</strong> file and session directories are optional and should only be included if you have multiple sessions per participant.</li>
      </ul>

      <p className="mb-2">For questions or assistance, please contact us at <a href="mailto:contact@radiata.ai" className="text-blue-500 underline">contact@radiata.ai</a>.</p>
    </div>
    </div>


    </form>
    );
}

export default UploadForm;