// About.jsx

import React from 'react';
import { Hero } from './Hero';
import { PrimaryFeatures } from './PrimaryFeatures';
import { SecondaryFeatures } from './SecondaryFeatures';
import { Pricing } from './Pricing';
import { Team } from './Team';
import { Faqs } from './Faqs';

export default function About() {
  return (
    <div className="about-container bg-custom-gray">
      <Hero />
      <PrimaryFeatures />
      <SecondaryFeatures />
      <Pricing />
      <Team />
      <Faqs />
    </div>
  );
}