import { createRoot } from "react-dom/client";
import { AuthContextProvider } from "./auth/AuthContext";
import Router from "./Router";
import { StrictMode } from "react";
import "./index.css";

// import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  </StrictMode>
);
