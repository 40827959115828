import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { signUp } from '../lib/allauth'
import { Link, useNavigate } from 'react-router-dom'
import { useConfig } from '../auth'
import ProviderList from '../socialaccount/ProviderList'
import Button from '../components/Button'
export default function Signup () {
  const [email, setEmail] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [password2Errors, setPassword2Errors] = useState([])
  const [response, setResponse] = useState({ fetching: false, content: null })
  const config = useConfig()
  const hasProviders = config.data.socialaccount?.providers?.length > 0
  const navigate = useNavigate()

  function submit () {
    if (password2 !== password1) {
      setPassword2Errors([{ param: 'password2', message: 'Password does not match.' }])
      return
    }
    setPassword2Errors([])
    setResponse({ ...response, fetching: true })
    signUp({ email, password: password1 }).then((content) => {
      setResponse((r) => { return { ...r, content } })
      navigate('/account/verify-email')
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray p-4">
      <div className="w-full max-w-md bg-custom-gray">

        <form onSubmit={(e) => { e.preventDefault(); submit(); }} className="space-y-2">
          <div>
            <label className="block text-black" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              required
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:teal-green"
            />
            <FormErrors param='email' errors={response.content?.errors} />
          </div>

          <div>
            <label className="block text-black mb-1" htmlFor="password1">
              Password
            </label>
            <input
              id="password1"
              autoComplete='new-password'
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              type='password'
              required
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:teal-green"
            />
            <FormErrors param='password' errors={response.content?.errors} />
          </div>

          <div>
            <label className="block text-black mb-1" htmlFor="password2">
              Password (again)
            </label>
            <input
              id="password2"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              type='password'
              required
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:teal-green"
            />
            <FormErrors param='password2' errors={password2Errors} />
          </div>

          <Button
            disabled={response.fetching}
            className="w-full bg-green/80 text-white py-1 px-4 rounded-md hover:bg-green/90 active:bg-green focus:outline-black disabled:bg-green/70"
            onClick={() => submit()}
          >
            {response.fetching ? 'Signing up...' : 'Sign Up'}
          </Button>
        </form>
        <br></br>

        <p className="mb-4 text-center">
          Already have an account? <Link to='/account/login' className="text-teal-green/80 hover:text-teal-green/90 active:text-teal-green">Log in here.</Link>
        </p>

        <FormErrors errors={response.content?.errors} />

        {/* <a href='/account/signup/passkey' className="block text-center mt-4 text-blue-500 hover:underline">Sign up using a passkey</a> */}

        {hasProviders && (
          <>
            <h2 className="text-center text-lg font-semibold mt-6 mb-3">Or use a third-party</h2>
            <ProviderList callbackURL='/account/provider/callback' />
          </>
        )}
      </div>
    </div>
  )
}