import MatrixPlot from "./MatrixPlot";

function FeatureMatrix({ feat_mat, classes, feat_names, figureTitle }) { 
    const figuresDiv = document.getElementsByClassName('figures-container')[0];
    const width = (figuresDiv.clientWidth);   
    const height = width / 2;
  
    const onClickAction = ({i, j}) => {
      //console.log('Add feature matrix click action')
    };
  
    return (
      <>
        <div className="feature-matrix mr-10">
          <MatrixPlot 
            matrix={feat_mat}
            figureTitle={figureTitle}
            rowLabels={classes}
            columnLabels={feat_names}
            width={width}
            height={height}
            onClickAction={onClickAction}
            showValues={false}
            setColorscale={'bwr'}
            axisNames={{'x': 'Feature', 'y': 'Class'}}
          />
        </div>
      </>
    );
  }

  export default FeatureMatrix;