import * as d3 from "d3";
import { useRef, useEffect } from "react";
import { Legend } from "./helpers";


function MatrixPlot({
  matrix,
  figureTitle = "",
  rowLabels,
  columnLabels,
  width,
  height,
  onClickAction,
  showValues = true,
  setColorscale = "wb",
  axisNames = { x: "", y: "" },
  labelAxes = true,
  labelTicks = true,
}) {
  const d3Container = useRef(null);

  useEffect(() => {
    if (matrix.length !== 0) {
      // Clear previous canvas and SVG elements
      d3.select(d3Container.current).select("canvas").remove();
      d3.select(d3Container.current).select("svg").remove();

      // Adjust scaling factor
      const scaleFactor = window.devicePixelRatio || 1;
      // Reduce the canvas dimensions
      let canvasWidth = width * scaleFactor; // Reduce to 80% of width
      let canvasHeight = height * scaleFactor; // Reduce to 80% of height

      // Adjust margins if needed
      const margins = {
        top: 0.1 * canvasHeight,
        right: 0.1 * canvasWidth,
        bottom: 0.1 * canvasHeight,
        left: 0.15 * canvasWidth,
      };

      const canvas = d3
        .select(d3Container.current)
        .append("canvas")
        .attr("width", canvasWidth)
        .attr("height", canvasHeight)
        .attr("class", "cursor-pointer") // Tailwind class
        .style("width", `${width}px`)
        .style("height", `${height}px`)
        .node();

      let matrixWidth = width - margins.left - margins.right;
      let matrixHeight = height - margins.top - margins.bottom;

      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.scale(scaleFactor, scaleFactor);
      const x = d3
        .scaleBand()
        .domain(d3.range(matrix[0].length))
        .range([margins.left, width - margins.right])
        .paddingInner(0);
      const y = d3
        .scaleBand()
        .domain(d3.range(matrix.length))
        .range([margins.top, height - margins.bottom])
        .paddingInner(0);

      // Colorscale
      let [minVal, maxVal] = d3.extent(matrix.flat());

      // Handle case when minVal equals maxVal
      if (minVal === maxVal) {
        minVal -= 1;
        maxVal += 1;
      }

      let colorScale;
      if (setColorscale === "wb") {
        colorScale = d3
          .scaleLinear()
          .domain([minVal, maxVal])
          .range(["white", "blue"]);
      } else if (setColorscale === "bwr") {
        colorScale = d3
          .scaleLinear()
          .domain([minVal, (minVal + maxVal) / 2, maxVal])
          .range(["blue", "white", "red"]);
      } else {
        // Default color scale
        colorScale = d3
          .scaleLinear()
          .domain([minVal, maxVal])
          .range(["white", "black"]);
      }

      // Draw cell
      const columnCount = matrix[0].length;
      const drawCell = (i, j, value, highlight = false, click = false) => {
        context.beginPath();
        context.rect(x(j), y(i), x.bandwidth(), y.bandwidth());
        context.fillStyle = colorScale(value);
        context.fill();

        if (click) {
          context.strokeStyle = "red";
          context.lineWidth = 0;
          context.stroke();
        } else if (highlight) {
          context.strokeStyle = "red";
          context.lineWidth = 0;
          context.stroke();
        } else if (columnCount > 20) {
          context.strokeStyle = "black";
          context.lineWidth = 0;
          context.stroke();
        } else {
          context.strokeStyle = "black";
          context.lineWidth = 0;
          context.stroke();
        }

        if (showValues) {
          context.fillStyle = "black";
          context.textAlign = "center";
          context.textBaseline = "middle";
          context.font = "18px Arial";
          context.fillText(
            value,
            x(j) + x.bandwidth() / 2,
            y(i) + y.bandwidth() / 2
          );
        }
      };

      // Matrix cell labels
      const drawLabels = () => {
        context.font = "18px Arial";
        context.fillStyle = "black";
        if (labelTicks) {
          // rows
          rowLabels.forEach((label, i) => {
            context.textAlign = "end";
            context.textBaseline = "middle";
            context.fillText(label, margins.left - 5, y(i) + y.bandwidth() / 2);
          });

          // columns
          columnLabels.forEach((label, i) => {
            context.save();
            context.translate(x(i) + x.bandwidth() / 2, margins.top - 5);
            context.rotate(-Math.PI / 2);
            context.textAlign = "start";
            context.textBaseline = "middle";
            context.fillText(label, 0, 0);
            context.restore();
          });
        }

        // Matrix axis labels
        if (labelAxes) {
          // row
          context.save();
          context.translate(
            matrixWidth + margins.left + 15,
            matrixHeight / 2 + margins.top
          );
          context.rotate(Math.PI / 2);
          context.textAlign = "center";
          context.textBaseline = "middle";
          context.fillText(axisNames.y, 0, 0);
          context.restore();

          // column
          context.save();
          context.translate(
            margins.left + matrixWidth / 2,
            matrixHeight + margins.top + 20
          );
          context.textAlign = "center";
          context.textBaseline = "middle";
          context.fillText(axisNames.x, 0, 0);
          context.restore();
        }
      };

      // Create matrix
      drawLabels();
      matrix.forEach((row, i) => {
        row.forEach((value, j) => {
          drawCell(i, j, value);
        });
      });

      // Matrix outline
      const outline = true;
      if (outline) {
        context.beginPath();
        context.rect(x(0), y(0), matrixWidth, matrixHeight);
        context.strokeStyle = "black";
        context.lineWidth = 1;
        context.stroke();
      }

      const legend = Legend(colorScale, {
        title: "Count",
        width: width, // Legend width
        height: 44,
        matrixPlotWidth: width, // Pass the matrix plot width
      });

      d3.select(d3Container.current).append(() => legend);


      // Tooltip
      var tooltip = d3.select("#heatmap-tooltip");
      tooltip.style("opacity", 0);

      let lastHovered = null;
      let lastClicked = null;

      const mousemove = function (event) {
        const [mx, my] = d3.pointer(event);
        let j = Math.floor((mx - margins.left) / x.bandwidth());
        let i = Math.floor((my - margins.top) / y.bandwidth());

        // if hover is within matrix bounds
        if (
          i >= 0 &&
          i < matrix.length &&
          j >= 0 &&
          j < matrix[0].length
        ) {
          // If new cell is selected
          if (lastHovered && (lastHovered.i !== i || lastHovered.j !== j)) {
            // Clear entire matrix
            context.clearRect(0, 0, canvasWidth, canvasHeight);

            // Redraw entire matrix
            drawLabels();
            matrix.forEach((row, ri) => {
              row.forEach((value, rj) => {
                const stroke = ri === i && rj === j;
                drawCell(ri, rj, value, stroke);
              });
            });

            if (outline) {
              context.beginPath();
              context.rect(x(0), y(0), matrixWidth, matrixHeight);
              context.strokeStyle = "black";
              context.lineWidth = 0;
              context.stroke();
            }

            if (lastClicked) {
              drawCell(
                lastClicked.i,
                lastClicked.j,
                matrix[lastClicked.i][lastClicked.j],
                false,
                true
              );
            }

            tooltip.style("opacity", 1);
            tooltip
              .html(
                `${axisNames.y}: ${rowLabels[i]}<br>${axisNames.x}: ${columnLabels[j]}<br>Value: ${matrix[
                  i
                ][j].toFixed(2)}`
              )
              .style("left", event.pageX + 10 + "px")
              .style("top", event.pageY - 10 + "px");

            lastHovered = { i, j };
            drawCell(i, j, matrix[i][j], true);
          } else if (!lastHovered) {
            // If original cell is selected
            drawCell(i, j, matrix[i][j], true);

            tooltip.style("opacity", 1);
            tooltip
              .html(
                `${axisNames.y}: ${rowLabels[i]}<br>${axisNames.x}: ${columnLabels[j]}<br>Value: ${matrix[
                  i
                ][j].toFixed(2)}`
              )
              .style("left", event.pageX + 10 + "px")
              .style("top", event.pageY - 10 + "px");

            lastHovered = { i, j };
          }
        } else if (lastHovered) {
          // If mouse moves off matrix, clear and redraw whole matrix
          context.clearRect(0, 0, canvasWidth, canvasHeight);

          drawLabels();
          matrix.forEach((row, ri) => {
            row.forEach((value, rj) => {
              drawCell(ri, rj, value, false);
              if (lastClicked) {
                drawCell(
                  lastClicked.i,
                  lastClicked.j,
                  matrix[lastClicked.i][lastClicked.j],
                  false,
                  true
                );
              }
            });
          });

          if (outline) {
            context.beginPath();
            context.rect(x(0), y(0), matrixWidth, matrixHeight);
            context.strokeStyle = "black";
            context.lineWidth = 0;
            context.stroke();
          }

          tooltip.style("opacity", 0);
          lastHovered = null;
        }
      };

      d3.select(canvas).on("mousemove", mousemove);
      d3.select(canvas).on("click", () => {
        if (lastHovered) {
          if (
            lastClicked &&
            lastClicked.i === lastHovered.i &&
            lastClicked.j === lastHovered.j
          ) {
            // Deselect the current cell
            drawCell(
              lastHovered.i,
              lastHovered.j,
              matrix[lastHovered.i][lastHovered.j],
              false,
              false
            );
            onClickAction(null);
            lastClicked = null;
          } else {
            // Select the new cell
            drawCell(
              lastHovered.i,
              lastHovered.j,
              matrix[lastHovered.i][lastHovered.j],
              false,
              true
            );
            onClickAction(lastHovered);
            lastClicked = lastHovered;
          }
        }
      });
    }
  }, [matrix, rowLabels, width, height]);

  return (
    <>
      <div>
        {figureTitle && (
          <h3 className="text-xl font-bold mb-4">{figureTitle}</h3>
        )}
        <div ref={d3Container} className="relative"></div>
      </div>
      <div
        id="heatmap-tooltip"
        className="fixed opacity-0 bg-gray-700 text-white text-sm px-2 py-1 rounded pointer-events-none z-50"
      ></div>
    </>
  );
}

export default MatrixPlot;
