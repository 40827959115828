import { useEffect, useState } from "react";
import * as d3 from "d3";


function useInteractiveLegend(svg, svgType, dimensions, categories, legendColor, columnType, selectedColumn, currentCategoryBins, setSelectedGroupOpacity, dataGlobal) {
    const [legendSelection, setLegendSelection] = useState({'column': selectedColumn, 'selection': ''});
  
    useEffect(() => {
      // if selected column changes, reset legend state
      if (selectedColumn!=legendSelection.column) {
        setLegendSelection({'column': selectedColumn, 'selection': ''})
        setSelectedGroupOpacity({'indices': []});
      }
      const width = dimensions.width;
      const height = dimensions.height;
      const margins = dimensions.margins;
      const legendWidth = dimensions.legendWidth;
      
      // svgType is one of 'scatter', 'roc'
      // columnType is one of 'continuous', 'categorical'
      const legendContainer = svg.append('g')
        .attr('class', 'scatterLegend')
        .attr('transform', (d, i) => svgType === 'scatter' ? 
              `translate(${margins.left}, ${margins.top})` :
              `translate(${margins.left + ((width-margins.left-margins.right)*0.7)}, ${margins.top + ((height-margins.top-margins.bottom)*0.55)})`)

      // Legend Box (adjust width and height as needed)
      const legendBox = legendContainer.append('rect')
        .attr('x', -10)
        .attr('y', -10)
        .attr('width', legendWidth)
        .attr('height', 16 *  categories.length)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr('rx', 5)
        .attr('ry', 5)
        .style("opacity", 0.9);

      const scatterLegend = legendContainer.selectAll('.legendEntry')
        .data(categories)
        .enter()
        .append('g') 
        .attr('transform', (d, i) => `translate(0, ${i * 15})`)
        .each(function(d, i) {
          d3.select(this).on('click', e => handleClick(e, d, i));
        });
  
      const legendCircles = scatterLegend.append('circle')
        .attr('r', 4)
        .attr('fill', columnType === 'continuous' ? 
          ((d, i) => {
            const [minValue, maxValue] = currentCategoryBins.invertExtent(i);
            const binAvg = (minValue + maxValue) / 2;
            return legendColor(binAvg);
          }) : 
          (d => legendColor(d)))
        .attr('class', function(d,i) {
          let classString;
          columnType === 'continuous' ? 
          classString = `legend-circle bin${i}` :
          classString = `legend-circle ${d}`
          return classString;
        })
        .style('cursor', 'pointer')
        .style('opacity', function(d,i) {
          let opacity;
          ((legendSelection.selection === `${d}`) || (legendSelection.selection === '')) ?
            opacity = 1 :
            opacity = 0.05;
          return opacity;
        });
  
      const legendTexts = scatterLegend.append('text')
        .attr('x', 10)
        .attr('y', 5)
        .attr('class', function(d,i) {
          let classString = 'legend-text';
          columnType === 'continuous' ? 
          classString += ` bin${i}` :
          classString += ` ${d}`
          return classString;
        })
        .style("cursor", "pointer")
        .style('font-size', '12px')
        .style('opacity', function(d,i) {
          let opacity;
          ((legendSelection.selection === `${d}`) || (legendSelection.selection === '')) ?
            opacity = 1 :
            opacity = 0.05;
          return opacity;
        })
        .text(d => d);
  
      let maxWidth = 0;
      legendContainer.selectAll('text').each(function() {
        maxWidth = Math.max(this.getBBox().width, maxWidth);
      });
      maxWidth += 25;
  
      legendBox.attr('width', maxWidth);
  
      function handleClick(event, categoryName, i) {
        let isSelectedCategory;
        let currentLegendSelection;
        let currentScatterIndices;
        // get indices for all scatter circles with current category
        columnType === 'continuous' ?
          isSelectedCategory = svg.selectAll(`.bin${i}`) :
          isSelectedCategory = svg.selectAll(`.${categoryName}`);
        const allElements = svg.selectAll(".roc-circle, .roc-line, .legend-circle, .legend-text");
        const nonSelectedCategories = allElements.filter((_,i,node) => !isSelectedCategory.nodes().includes(node[i]));
        isSelectedCategory.raise();
  
        // if clicked legend entry is already selected, unselect it
        if (categoryName === legendSelection.selection) {
          currentLegendSelection = {'column': selectedColumn, 'selection': ''};
          currentScatterIndices = [];
          if (svgType === 'roc') {
            allElements.style('opacity', 1);
          }
        } else { // otherwise, select clicked legend entry
          currentLegendSelection = categoryName;
          currentLegendSelection = {'column': selectedColumn, 'selection': categoryName};
          currentScatterIndices = isSelectedCategory.data().map(d => d.id);
          currentScatterIndices = currentScatterIndices.filter(item => !isNaN(item));
          if (svgType === 'roc') {
            isSelectedCategory.style('opacity', 1);
            nonSelectedCategories.style('opacity', 0.05);
          }
        }
        setLegendSelection(currentLegendSelection);
        // 'scatter' updates setSelectedGroupOpacity, 'roc' does not
        if (svgType === 'scatter') {
          setSelectedGroupOpacity({ 'indices': currentScatterIndices });
        }
      }
    },[selectedColumn, categories, legendSelection, dataGlobal]);
  }
  
  export default useInteractiveLegend;