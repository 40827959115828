import * as d3 from "d3";
import { useRef, useEffect } from "react";
import { Legend } from "./helpers";

function RegressionPlot({
  model,
  model_type,
  figureTitle,
  setSelectedSingleIndex,
  dataGlobal,
}) {
  const d3Container = useRef(null);

  useEffect(() => {
    // Define dimensions and margins
    const svg = d3.select(d3Container.current);
    const figuresDiv = document.getElementsByClassName("figures-container")[0];
    const width = figuresDiv.clientWidth * 0.95;
    const height = width * 0.5;
    const margins = {
      top: 0.02 * height,
      right: 0.02 * width,
      bottom: 0.2 * height,
      left: 0.1 * width,
    };

    svg
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", [0, 0, width, height]);

    const tooltip = d3
      .select("body")
      .append("div")
      .attr(
        "class",
        "absolute opacity-0 bg-gray-700 text-white text-sm px-2 py-1 rounded pointer-events-none"
      );

    const regressionResults = model.modelResults;
    const regressionParameters = model.modelParameters;

    // Create scales
    const xExtent = d3.extent(regressionResults[model_type + "_response"]);
    const yExtent = d3.extent(regressionResults[model_type + "_prediction"]);

    const overallMin = Math.min(xExtent[0], yExtent[0]);
    const overallMax = Math.max(xExtent[1], yExtent[1]);

    const xScale = d3
      .scaleLinear()
      .domain([overallMin, overallMax])
      .range([margins.left, width - margins.right]);
    const yScale = d3
      .scaleLinear()
      .domain([overallMin, overallMax])
      .range([height - margins.bottom, margins.top]);

    // Axes
    const xAxis = svg
      .append("g")
      .attr("transform", `translate(0, ${height - margins.bottom})`)
      .attr("stroke-width", 2)
      .call(d3.axisBottom(xScale));

    xAxis
      .append("text")
      .attr("class", "text-sm")
      .attr("y", margins.bottom * 0.7)
      .attr(
        "x",
        (width - margins.left - margins.right) / 2 + margins.left
      )
      .attr("fill", "#000")
      .attr("text-anchor", "middle")
      .text(`Actual ${regressionParameters.response_variable_names[0]}`);

    const yAxis = svg
      .append("g")
      .attr("transform", `translate(${margins.left},0)`)
      .attr("stroke-width", 2)
      .call(d3.axisLeft(yScale));

    yAxis
      .append("text")
      .attr("class", "text-sm")
      .attr("y", -(margins.left * 0.65))
      .attr(
        "x",
        -(height - margins.bottom - margins.top) / 2 + margins.top
      )
      .attr("fill", "#000")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text(`Estimated ${regressionParameters.response_variable_names[0]}`);

    // Regression line
    svg
      .append("line")
      .style("stroke", "black")
      .style("stroke-width", 2)
      .attr("x1", xScale(overallMin))
      .attr("y1", yScale(overallMin))
      .attr("x2", xScale(overallMax))
      .attr("y2", yScale(overallMax));

    // Circle colors
    let [minVal, maxVal] = d3.extent(
      regressionResults[model_type + "_response_residuals"]
    );

    let categoryBins = d3
      .scaleQuantize()
      .domain([minVal, maxVal])
      .range(d3.range(0, 6));

    // Adjust minVal to take absolute value
    minVal = -maxVal;

    let circleColors = d3
      .scaleLinear()
      .domain([minVal, (minVal + maxVal) / 2, maxVal])
      .range(["blue", "#d3d3d3", "red"])
      .interpolate(d3.interpolateRgb);

    // Regression data points
    let selectedPoint = { index: null, class_name: null };

    svg
      .append("g")
      .selectAll(`circle.regression-circle.${model_type}`)
      .data(
        regressionResults[model_type + "_response"].map((d, i) => ({
          response: d,
          prediction: regressionResults[model_type + "_prediction"][i],
          residual:
            regressionResults[model_type + "_response_residuals"][i],
          index: i,
          globalIndex: regressionParameters[model_type + "_indices"][i],
        }))
      )
      .join("circle")
      .attr("class", `regression-circle ${model_type}`)
      .attr("cx", (d) => xScale(d.response))
      .attr("cy", (d) => yScale(d.prediction))
      .attr("r", 3)
      .attr("fill", (d) => circleColors(d.residual))
      .style("cursor", "pointer")
      .on("mouseover", function (e, d) {
        if (!(d.index == selectedPoint.index)) {
          d3.select(this).attr("stroke", "#333").attr("stroke-width", 4);
        }
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `Scan ID: ${d.globalIndex}<br/>
            Actual ${regressionParameters.response_variable_names[0]}: ${d.response.toFixed(
              2
            )}<br/>
            Estimated ${regressionParameters.response_variable_names[0]}: ${d.prediction.toFixed(
              2
            )}<br/>
            Residual ${regressionParameters.response_variable_names[0]}: ${d.residual.toFixed(
              2
            )}<br/>`
          )
          .style("left", e.clientX + 5 + "px")
          .style("top", e.clientY + 5 + "px");
      })
      .on("mouseout", function (e, d) {
        if (!(d.index == selectedPoint.index)) {
          d3.select(this).attr("stroke", null).attr("stroke-width", null);
        }
        tooltip.transition().style("opacity", 0);
      })
      .on("click", function (e, d) {
        if (d.index == selectedPoint.index) {
          selectedPoint = { index: null, class_name: null };
          d3.select(this)
            .attr("r", 3)
            .attr("stroke", null)
            .attr("stroke-width", null);
          setSelectedSingleIndex(null);
        } else {
          svg
            .selectAll(`circle.regression-circle.${model_type}`)
            .attr("r", 3)
            .attr("stroke", null)
            .attr("stroke-width", null);

          selectedPoint = { index: d.index, class_name: null };
          setSelectedSingleIndex(d.globalIndex);

          d3.select(this)
            .attr("stroke", "red")
            .attr("stroke-width", 4)
            .raise();
        }
      });

    // Colorbar legend
    const legendContainer = svg
      .append("g")
      .attr("class", "scatterLegend")
      .attr(
        "transform",
        `translate(${
          margins.left + (width - margins.left - margins.right) * 0.7
        }, ${margins.top + (height - margins.top - margins.bottom) * 0.75})`
      );

    let legendCheck = svg.select("#regressionLegend");
    if (legendCheck.empty()) {
      let regressionLegend = Legend(circleColors, {
        title: "residual",
        width: width * 0.25,
        height: height * 0.2,
      });
      legendContainer.node().append(regressionLegend);
    }

    return () => {
      tooltip.remove();
    };
  }, []);

  return (
    <>
      <div>
        {figureTitle && (
          <h3 className="text-xl font-bold mb-4">{figureTitle}</h3>
        )}
        <div className="relative">
          <svg className="w-full h-full" ref={d3Container} />
        </div>
      </div>
    </>
  );
}

export default RegressionPlot;
