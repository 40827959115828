import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { login } from '../lib/allauth'
import { Link } from 'react-router-dom'
import { useConfig } from '../auth'
import ProviderList from '../socialaccount/ProviderList'
import Button from '../components/Button'
import App from '../App'

export default function Login () {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [response, setResponse] = useState({ fetching: false, content: null })
  const config = useConfig()
  const hasProviders = config.data.socialaccount?.providers?.length > 0

  function submit () {
    setResponse({ ...response, fetching: true })
    login({ email, password }).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray p-4">
      <div className="w-full max-w-md bg-custom-gray ">
        

        <form onSubmit={(e) => { e.preventDefault(); submit(); }} className="space-y-2">
          <div>
            <label className="block text-black mb-1" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              required
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:teal-green"
            />
            <FormErrors param='email' errors={response.content?.errors} />
          </div>

          <div>
            <label className="block text-black mb-1" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              required
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:teal-green"
            />
            <div className="flex justify-between items-center mt-1">
              <Link to='/account/password/reset' className="text-sm text-teal-green/80 hover:text-teal-green">
                Forgot your password?
              </Link>
            </div>
            <FormErrors param='password' errors={response.content?.errors} />
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-2">
            <Button
              disabled={response.fetching}
              onClick={() => submit()}
              className="w-full sm:w-1/2 bg-green/80 text-white py-1 px-3 rounded-md hover:bg-green/90 active:bg-green disabled:bg-green/70 focus:outline-black"
            >
              {response.fetching ? 'Logging in...' : 'Log in'}
            </Button>
            <Link
              className="mt-2 sm:mt-0 sm:w-1/2 inline-flex justify-center items-center bg-green/80 text-white py-1 px-3 rounded-md hover:bg-green/90 active:bg-green disabled:bg-green/70 focus:outline-black"
              to='/account/login/code'
            >
              Mail me a sign-in code
            </Link>
          </div>
        </form>
        <br></br>
        <p className="mb-3 text-center">
          No account? <Link to='/account/signup' className="text-teal-green/80 hover:text-teal-green/90 active:text-teal-green">Sign up here.</Link>
        </p>

        <FormErrors errors={response.content?.errors} />

        {/* Uncomment the section below if you have third-party providers configured
            and want to offer sign-in options via those providers.

          {hasProviders && (
            <>
              <h2 className="text-center text-lg font-semibold mt-4 mb-2">Or use a third-party</h2>
              <ProviderList callbackURL='/account/provider/callback' />
            </>
          )}
        */}
      </div>
    </div>
    
  )
}