import { useRef, useEffect, useState, useContext, useCallback, useMemo } from "react";
import MatrixPlot from "./MatrixPlot";

function ConfusionMatrix({ confusionMatrixData, confusionMatrixIndices, classes, figureTitle, setSelectedSingleIndex, setSelectedGroup }) { 
    //const [confusionMatrixLastClicked, setConfusionMatrixLastClicked] = useState([]);
    const confusionMatrixLastClickedRef = useRef(null);
    
    const figuresDiv = document.getElementsByClassName('figures-container')[0];
    const width = (figuresDiv.clientWidth * 0.48);   
    const height = (figuresDiv.clientWidth * 0.50);
  
    // Take the selected indices from 'confusionMatrixIndices' based on the clicked cell
    const onClickAction = (rowCol) => {
    // If rowCol is null, handle the deselection case
    if (rowCol === null) {
      setSelectedGroup({'indices': [], 'colors': []});
      confusionMatrixLastClickedRef.current = null;  // Reset selection
    } else {
      // Handle the case where a new cell is clicked
      if (confusionMatrixLastClickedRef.current !== rowCol) {
        const chosenClasses = `${classes[rowCol.i]}/${classes[rowCol.j]}`;
        const currentIndices = confusionMatrixIndices[chosenClasses];
        let currentColors = [];
        for (let n = 0; n < currentIndices.length; n++) {
          currentColors[n] = (rowCol.i === rowCol.j) ? 'lime' : 'red';
        }
        setSelectedGroup({'indices': currentIndices, 'colors': currentColors});
        confusionMatrixLastClickedRef.current = rowCol;
      } else {
        // If the same cell is clicked, deselect it
        setSelectedGroup({'indices': [], 'colors': []});
        confusionMatrixLastClickedRef.current = null;  // Reset selection
      }
    }
  };
  
    return (
      <>
        <div className="confusion-matrix">
          <MatrixPlot 
            matrix={confusionMatrixData}
            figureTitle={figureTitle}
            rowLabels={classes}
            columnLabels={classes}
            width={width}
            height={height}
            onClickAction={onClickAction}
            showValues={true}
            setColorscale={'wb'}
            axisNames={{'x': 'Predicted Class', 'y': 'True Class'}}
          />
        </div>
      </>
    );
  } 
  

export default ConfusionMatrix;