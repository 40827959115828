import React from 'react';
//import './ConfirmationModal.css'; 


const ConfirmationModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 md:p-8 rounded-lg max-w-md w-11/12 text-center">
          <h3 className="text-2xl font-semibold mb-4">{title}</h3>
          <p className="text-gray-700 mb-6">{message}</p>
          <div className="flex justify-center gap-4">
            <button
              onClick={onConfirm}
              className="modal-button confirm-button px-4 py-2 bg-red-900/80 hover:bg-red-900/90 active:bg-red-900 text-white rounded-md"
            >
              Confirm
            </button>
            <button
              onClick={onCancel}
              className="modal-button cancel-button px-4 py-2 bg-teal-green/80 hover:bg-teal-green/90 active:bg-teal-green text-white rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ConfirmationModal;