import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { requestPasswordReset } from '../lib/allauth'
import { Link } from 'react-router-dom'
import Button from '../components/Button'

export default function RequestPasswordReset () {
  const [email, setEmail] = useState('')
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    setResponse({ ...response, fetching: true })
    requestPasswordReset(email).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  if (response.content?.status === 200) {
    return (
      <div className="flex flex-col items-center justify-center bg-custom-gray p-4">
        <div className="w-full max-w-md p-6">
          <p className="mb-4 text-center">Password reset sent.</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center bg-custom-gray p-4">
      <div className="w-full max-w-md bg-custom-gray">
        

        <form onSubmit={(e) => { e.preventDefault(); submit(); }} className="space-y-4">
          <div>
            <label className="block text-black mb-1" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
              required
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:teal-green"
            />
            <FormErrors param='email' errors={response.content?.errors} />
          </div>
          <Button
            disabled={response.fetching}
            className="w-full bg-green/80 text-white py-1 px-4 rounded-md hover:bg-green/90 active:bg-green disabled:bg-green/70 focus:outline-black"
            onClick={() => submit()}
          >
            {response.fetching ? 'Sending Reset Link...' : 'Reset Password'}
          </Button>
        </form>
      </div>
      <br></br>
      <p className="mb-4 text-center">
          Remember your password?{' '}
          <Link to='/account/login' className="text-teal-green/80 hover:text-teal-green/90 active:text-teal-green">Back to login.</Link>
        </p>

        <FormErrors errors={response.content?.errors} />
    </div>
  )
}