import { useUser } from './auth'
import App from './App'
import { useEffect } from 'react';
import Root from './Root'
import { useContext } from 'react';
import { AuthContext } from './auth/AuthContext';
import { AuthContextProvider } from "./auth/AuthContext";


export default function Dashboard () {
  const user = useUser()
  return (
    <div className="flex flex-col h-full">
    <AuthContextProvider>
      <Root />
      <div className="flex-1 min-h-0">
        <App />
      </div>
    </AuthContextProvider>
    </div>
  )
}