// frontend/src/PublicStudies.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCSRFToken } from './lib/django';
import ConfirmationModal from './components/ConfirmationModal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import { buildDataGlobalColumnInfo } from './dataGlobalColumnInfoBuilder';
import CustomToolbar from "./CustomToolBar";

const apiBaseUrl = process.env.REACT_APP_API_URL;

async function fetchPresignedUrl(filename) {
  const csrfToken = getCSRFToken();
  try {
    const response = await axios.get(`${apiBaseUrl}/get_presigned_urls/`, {
      params: { object_name: filename },
      headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return response.data.url;
  } catch (error) {
    console.error("Error fetching presigned URL:", error);
    throw error;
  }
}

function convertQandAToTags(ds) {
    let QandA = ds.QandA;
    let citation = ds.citation;
    return QandA.map(item => {
      let colorClass = item.a.toLowerCase().includes('yes')
        ? 'bg-kelly-green/80 text-white'
        : 'bg-red-100 text-red-800';
  
      // Example special-case color assignments if desired:
      if (item.tag === 'Citation required') {
        colorClass = 'bg-blue-100 text-blue-800';
      } else if (item.tag === 'No Approval required' || item.tag === 'Ok for researchers' || item.tag === 'Ok for companies' || item.tag === 'Ok for research' || item.tag === 'Ok for commercial applications' || item.tag === 'Ok to redistribute' || item.tag === 'Ok for open source datasets' || item.tag === 'Ok for anonymized display') {
        colorClass = 'bg-kelly-green/80 text-white';
      } else if (item.tag === 'Approval required') {
        colorClass = 'bg-blue-100 text-blue-800';
      }
  
      let tagReason;
      if (item.tag === 'Citation required') {
        tagReason = citation;
      } else {
        tagReason = item.reason;
      }

      return { text: item.tag, colorClass, reason: tagReason };
    });
  }


function PublicStudies() {
  const [searchTerm, setSearchTerm] = useState('');
  
  const [approvalRequiredFilter, setApprovalRequiredFilter] = useState(false);
  const [radiataCanShareFilter, setRadiataCanShareFilter] = useState(false);
  const [okForResearchersFilter, setOkForResearchersFilter] = useState(false);
  const [okForCompaniesAffFilter, setOkForCompaniesAffFilter] = useState(false);
  const [okForResearchFilter, setOkForResearchFilter] = useState(false);
  const [okForCommercialFilter, setOkForCommercialFilter] = useState(false);
  const [okToRedistributeFilter, setOkToRedistributeFilter] = useState(false);
  const [okForOpenSourceFilter, setOkForOpenSourceFilter] = useState(false);
  const [okForAnonymizedFilter, setOkForAnonymizedFilter] = useState(false);
  const [hoveredTag, setHoveredTag] = useState(null);
  const [copiedTag, setCopiedTag] = useState(null);
  const [selectedPublicDataset, setSelectedPublicDataset] = useState(null);
  const [publicStudyData, setPublicStudyData] = useState([]);
  const [publicDatasetMessages, setPublicDatasetMessages] = useState({});
  const [userStudies, setUserStudies] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    // Fetch user studies
    axios
      .get(`${apiBaseUrl}/user/studies/`, {
        headers: {
          'X-CSRFToken': getCSRFToken(),
        },
        withCredentials: true,
      })
      .then((response) => {
        setUserStudies(response.data.studies);
      })
      .catch((error) => console.error('Error fetching user studies:', error));

    // Fetch the dataset_info.json from S3
    (async () => {
      try {
        const presignedUrl = await fetchPresignedUrl('studies_info/dataset_info.json');
        const datasetResponse = await axios.get(presignedUrl);
        setDatasets(datasetResponse.data);
      } catch (error) {
        console.error('Error fetching datasets JSON:', error);
      }
    })();

  }, []);


  function meetsAllSelectedFilters(ds) {
    const answerFor = (id) => {
      const entry = ds.QandA.find(item => item.qId === id);
      return entry && entry.a.toLowerCase().includes('yes');
    };
  
    // Use qId directly for each filter:
    // qId=1: Approval required / No Approval required
    // qId=2: Radiata can share
    // qId=3: Ok for researchers
    // qId=4: Ok for companies
    // qId=5: Ok for research
    // qId=6: Ok for commercial applications
    // qId=7: Ok to redistribute
    // qId=8: Ok for open source datasets
    // qId=9: Ok for anonymized display
    // qId=10: Citation required
  
    if (approvalRequiredFilter && !answerFor(1)) return false;
    if (radiataCanShareFilter && !answerFor(2)) return false;
    if (okForResearchersFilter && !answerFor(3)) return false;
    if (okForCompaniesAffFilter && !answerFor(4)) return false;
    if (okForResearchFilter && !answerFor(5)) return false;
    if (okForCommercialFilter && !answerFor(6)) return false;
    if (okToRedistributeFilter && !answerFor(7)) return false;
    if (okForOpenSourceFilter && !answerFor(8)) return false;
    if (okForAnonymizedFilter && !answerFor(9)) return false;
  
    return true;
  }

  const filteredDatasets = datasets.filter((ds) => {
    const matchesSearch = ds.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          ds.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearch && meetsAllSelectedFilters(ds);
  });

  const setMessageForPublicDataset = (datasetName, type, message) => {
    setPublicDatasetMessages((prev) => ({
      ...prev,
      [datasetName]: {
        ...prev[datasetName],
        [type]: message,
      },
    }));
  
    // Optional: Add a timer to clear messages after a few seconds if desired
  };

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const fetchPublicStudyData = (studyId, datasetName) => {
    console.log([studyId, datasetName])
    axios
      .get(`${apiBaseUrl}/studies/${studyId}/data/`, {
      //.get(`${apiBaseUrl}/studies/${datasetName}/data/`, {
      // .get(`${apiBaseUrl}/studies/4/data/`, {
      // .get(`${apiBaseUrl}/user/studies/`, {
        headers: {
          'X-CSRFToken': getCSRFToken(),
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response)
        if (response.data && response.data.rows) {
          setPublicStudyData(response.data.rows);
        } else {
          setPublicStudyData([]);
          setMessageForPublicDataset(datasetName, 'error', 'No data available for this dataset.');
        }
      })
      .catch((error) => {
        console.error('Error fetching public dataset data:', error);
        setPublicStudyData([]);
        setMessageForPublicDataset(datasetName, 'error', 'Unable to fetch dataset data.');
      });
  };

  // Build columns for DataGrid
  const columnInfo = buildDataGlobalColumnInfo(publicStudyData);
  const visibleColumnsInfo = columnInfo.filter(col => col.visible);

  const columns = visibleColumnsInfo.map((colInfo) => {
    const column = {
      field: colInfo.name,
      width: colInfo.width,
      headerName: colInfo.name.replace(/_/g, ' '),
      type: colInfo.type,
    };

    // Special handling for 'id' field
    if (colInfo.name === 'id') {
      column.renderCell = (params) => {
        let noCommaNumber = parseFloat(params.value).toFixed(0);
        return noCommaNumber;
      };
    }

    // Add valueOptions for categorical columns
    if (colInfo.type == 'singleSelect' && colInfo.valueOptions.length > 0) {
      column.valueOptions = colInfo.valueOptions;
    }

    return column;
  });



  return (
    <div className="container mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      <h3 className="text-xl font-semibold mb-4">Public/Accessible Studies</h3>

      {/* Filter Zone */}
      <div className="mb-6 p-4 bg-gray-100 border rounded-md shadow-sm space-y-4">
        <h4 className="font-semibold mb-2">Filter Datasets</h4>
        {/* First row of exactly 5 filters */}
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={approvalRequiredFilter}
              onChange={(e) => setApprovalRequiredFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>No approval required</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={radiataCanShareFilter}
              onChange={(e) => setRadiataCanShareFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Radiata can share</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okForResearchersFilter}
              onChange={(e) => setOkForResearchersFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok for researchers</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okForCompaniesAffFilter}
              onChange={(e) => setOkForCompaniesAffFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok for companies</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okForResearchFilter}
              onChange={(e) => setOkForResearchFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok for research</span>
          </label>
        </div>

        {/* Second row with the remaining 4 filters */}
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okForCommercialFilter}
              onChange={(e) => setOkForCommercialFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok for commercial applications</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okToRedistributeFilter}
              onChange={(e) => setOkToRedistributeFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok to redistribute</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okForOpenSourceFilter}
              onChange={(e) => setOkForOpenSourceFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok for open source datasets</span>
          </label>
          <label className="flex items-center space-x-2 whitespace-nowrap">
            <input
              type="checkbox"
              checked={okForAnonymizedFilter}
              onChange={(e) => setOkForAnonymizedFilter(e.target.checked)}
              className="w-4 h-4 text-teal-green"
            />
            <span>Ok for anonymized display</span>
          </label>
        </div>

        <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4 mt-2">
          <input
            type="text"
            placeholder="Search by name or description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-1 px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
          />
        </div>
      </div>

      {filteredDatasets.length > 0 ? (
        <div className="space-y-4">
          {filteredDatasets.map((ds, datasetIndex) => {
            const userHasAccess = (ds.type === 'Public') || 
            userStudies.some((study) => study.name.toLowerCase() === ds.name.toLowerCase());        
            const tags = convertQandAToTags(ds);
            const half = Math.ceil(tags.length / 2);
            const topRow = tags.slice(0, half);
            const bottomRow = tags.slice(half);
            const isLastDataset = (datasetIndex === filteredDatasets.length - 1);

            return (
              <div key={ds.name} className="p-4 bg-gray-100 border rounded-md shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <strong className="text-lg">{ds.name}</strong>
                  <span
                    className={`text-sm font-medium ${
                      ds.type === 'Public'
                        ? 'text-green'
                        : ds.type === 'Accessible'
                        ? 'text-blue-500'
                        : 'text-red-900'
                    }`}
                  >
                    {ds.type}
                  </span>
                </div>
                
                <p className="mb-2">{ds.description}</p>
                <p className="mb-2">
                    <strong>Website: </strong>
                    {ds.website.map((url, index) => (
                        <React.Fragment key={index}>
                        <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                            {url}
                        </a>
                        {index < ds.website.length - 1 && ' | '}
                        </React.Fragment>
                    ))}
                </p>
                {ds.dataUseAgreement && (
                  <p className="mb-2">
                    <strong>Data Use Agreement:</strong>{' '}
                    <button
                      onClick={async () => {
                        try {
                          const presignedUrl = await fetchPresignedUrl(ds.dataUseAgreement.replace('s3://radiata-data/', ''));
                          window.open(presignedUrl, '_blank', 'noopener,noreferrer');
                        } catch (error) {
                          console.error('Error fetching DUA URL:', error);
                          // Optionally show an error message to the user
                        }
                      }}
                      className="text-blue-600 underline"
                    >
                      View DUA
                    </button>
                  </p>
                )}
                <p className="mb-4">
                  <strong>License Type:</strong> {ds.licenseType}
                </p>

                <div className="flex flex-wrap mb-2">
                {topRow.map((tag, i) => {
                  const isCitation = (tag.text === 'Citation required');
                  
                  const rowIdentifier = 'top'; // For topRow tags

                  const handleMouseEnter = () => {
                    setHoveredTag({ dsName: ds.name, row: rowIdentifier, index: i });
                  };
                  
                  const handleMouseLeave = () => {
                    setHoveredTag(null);
                  };
                  
                  const handleClick = () => {
                    if (isCitation) {
                      navigator.clipboard.writeText(tag.reason || '');
                      setCopiedTag({ dsName: ds.name, row: rowIdentifier, index: i });
                      setTimeout(() => {
                        setCopiedTag(null);
                      }, 2000);
                    }
                  };

                  const showTooltip = hoveredTag && hoveredTag.dsName === ds.name && hoveredTag.row === rowIdentifier && hoveredTag.index === i;
                  const isCopied = copiedTag && copiedTag.dsName === ds.name && copiedTag.row === rowIdentifier && copiedTag.index === i;
                  const tooltipText = (isCitation && isCopied) ? '✓ Copied' : tag.reason;

                  return (
                    <span
                      key={i}
                      className={`relative mb-1 mr-1 rounded-lg px-2 py-1 text-sm ${tag.colorClass} cursor-pointer`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleClick}
                    >
                      {isCitation && <ClipboardDocumentIcon className="h-4 w-4 mr-1 inline-block" />}
                      {tag.text}
                      {showTooltip && (
                        <div className={`absolute ${isLastDataset ? 'bottom-full mb-1' : 'top-full mt-1'} left-0 bg-gray-700 text-white text-base rounded px-2 py-1 flex items-center max-w-sm z-50 min-w-[200px]'`}>
                          <span>{tooltipText}</span>
                        </div>
                      )}
                    </span>
                  );
                })}
                </div>
                <div className="flex flex-wrap mb-4">
                {bottomRow.map((tag, i) => {
                  const isCitation = (tag.text === 'Citation required');
                  
                  const rowIdentifier = 'bottom';

                  const handleMouseEnter = () => {
                    setHoveredTag({ dsName: ds.name, row: rowIdentifier, index: i });
                  };
                  
                  const handleMouseLeave = () => {
                    setHoveredTag(null);
                  };
                  
                  const handleClick = () => {
                    if (isCitation) {
                      navigator.clipboard.writeText(tag.reason || '');
                      setCopiedTag({ dsName: ds.name, row: rowIdentifier, index: i });
                      setTimeout(() => {
                        setCopiedTag(null);
                      }, 2000);
                    }
                  };

                  const showTooltip = hoveredTag && hoveredTag.dsName === ds.name && hoveredTag.row === rowIdentifier && hoveredTag.index === i;
                  const isCopied = copiedTag && copiedTag.dsName === ds.name && copiedTag.row === rowIdentifier && copiedTag.index === i;
                  const tooltipText = (isCitation && isCopied) ? '✓ Copied' : tag.reason;

                  return (
                    <span
                      key={i}
                      className={`relative mb-1 mr-1 rounded-lg px-2 py-1 text-sm ${tag.colorClass} cursor-pointer`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleClick}
                    >
                      {isCitation && <ClipboardDocumentIcon className="h-4 w-4 mr-1 inline-block" />}
                      {tag.text}
                      {showTooltip && (
                        <div className={`absolute ${isLastDataset ? 'bottom-full mb-1' : 'top-full mt-1'} left-0 bg-gray-700 text-white text-base rounded px-2 py-1 flex items-center max-w-sm z-50 min-w-[200px]'`}>
                          <span>{tooltipText}</span>
                        </div>
                      )}
                    </span>
                  );
                })}
                </div>

                <div className="flex flex-wrap items-center space-x-2 mt-4">
                  {userHasAccess && (
                    <button
                      onClick={() => {
                        if (selectedPublicDataset === ds.name) {
                          // Hide data
                          setSelectedPublicDataset(null);
                          setPublicStudyData([]);
                        } else {
                          // View data
                          setSelectedPublicDataset(ds.name);
                          fetchPublicStudyData(ds.id, ds.name);
                        }
                      }}
                      className="bg-dark-blue/80 hover:bg-dark-blue/90 active:bg-dark-blue text-white px-4 py-1 rounded-md"
                    >
                      {selectedPublicDataset === ds.name ? 'Hide Data' : 'View Data'}
                    </button>
                  )}

                  {/* <button
                    className="bg-dark-blue/80 hover:bg-dark-blue/90 active:bg-dark-blue text-white px-4 py-1 rounded-md"
                  >
                    Download Data
                  </button> */}
                </div>

                {publicDatasetMessages[ds.name]?.error && (
                  <p className="text-red-900 mt-2" aria-live="polite">
                    {publicDatasetMessages[ds.name].error}
                  </p>
                )}

                {selectedPublicDataset === ds.name && (
                  <div className="mt-4">
                      <DataGridPro
                        pagination
                        slots={{ toolbar: CustomToolbar }}
                        density="compact"
                        rows={publicStudyData}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                          },
                          pinnedColumns: {
                            left: ["id"],
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                      />
                    </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-teal-green">No datasets match your filters.</p>
      )}
    </div>
  );
}

export default PublicStudies;