import { useState, useEffect } from 'react'
import * as allauth from '../lib/allauth'
import FormErrors from '../components/FormErrors'
import Button from '../components/Button'

export default function ChangeEmail () {
  const [email, setEmail] = useState('')
  const [emailAddresses, setEmailAddresses] = useState([])
  const [response, setResponse] = useState({ fetching: false, content: { status: 200, data: [] } })

  useEffect(() => {
    setResponse((r) => { return { ...r, fetching: true } })
    allauth.getEmailAddresses().then((resp) => {
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
      }
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }, [])

  function addEmail () {
    setResponse({ ...response, fetching: true })
    allauth.addEmail(email).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
        setEmail('')
      }
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  function requestEmailVerification (email) {
    setResponse({ ...response, fetching: true })
    allauth.requestEmailVerification(email).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  function deleteEmail (email) {
    setResponse({ ...response, fetching: true })
    allauth.deleteEmail(email).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
      }
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  function markAsPrimary (email) {
    setResponse({ ...response, fetching: true })
    allauth.markEmailAsPrimary(email).then((resp) => {
      setResponse((r) => { return { ...r, content: resp } })
      if (resp.status === 200) {
        setEmailAddresses(resp.data)
      }
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-semibold mb-6">Change Email</h1>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-1 px-4 border-b">Email</th>
              <th className="py-1 px-4 border-b">Verified</th>
              <th className="py-1 px-4 border-b">Primary</th>
              <th className="py-1 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {emailAddresses.map(ea => (
              <tr key={ea.email} className="hover:bg-gray-50">
                <td className="py-1 px-4 border-b text-center">{ea.email}</td>
                <td className="py-1 px-4 border-b text-center">
                  {ea.verified ? <span className="text-green-500">✅</span> : <span className="text-red-500">❌</span>}
                </td>
                <td className="py-1 px-4 border-b text-center">
                  <input
                    onChange={() => markAsPrimary(ea.email)}
                    type="radio"
                    checked={ea.primary}
                    className="form-radio h-4 w-4 text-teal-green"
                  />
                </td>
                <td className="py-1 px-4 border-b text-center space-x-2">
                  {!ea.verified && (
                    <Button
                      onClick={() => requestEmailVerification(ea.email)}
                      disabled={response.fetching}
                      className="bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white px-3 py-1 rounded"
                    >
                      Resend
                    </Button>
                  )}
                  {!ea.primary && (
                    <Button
                      onClick={() => deleteEmail(ea.email)}
                      disabled={response.fetching}
                      className="bg-red-500 hover:bg-red-600 active:bg-red-700 text-white px-3 py-1 rounded"
                    >Remove</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className="text-xl font-semibold mt-8 mb-4">Add Email</h2>

      <FormErrors errors={response.content?.errors} />

      <div className="mb-4">
        <label className="block text-black">
          Email
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required
            className="mt-1 w-full px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
          />
        </label>
        <FormErrors param="email" errors={response.content?.errors} />
      </div>

      <Button
        disabled={response.fetching}
        onClick={addEmail}
        className="w-full bg-green/80 hover:bg-green/90 active:bg-green text-white py-1 px-4 rounded-md disabled:opacity-50"
      >
        Add
      </Button>
    </div>
  )
}