import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';


function CustomToolbar() {
    // DataGrid toolbar
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton 
          slotProps={{ tooltip: { title: 'Filter rows' } }}
        />
        {/* <GridToolbarColumnsButton
          slotProps={{ tooltip: { title: 'Toggle columns' } }}
        /> */}
        {/* <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export data' },
            button: { variant: 'outlined' },
          }}
        /> */}
      </GridToolbarContainer>
    );
  }

  export default CustomToolbar
  