// frontend/src/Account.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getCSRFToken } from './lib/django';
import ConfirmationModal from './components/ConfirmationModal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Select, MenuItem } from '@mui/material';
import { buildDataGlobalColumnInfo } from './dataGlobalColumnInfoBuilder';
import CustomToolbar from "./CustomToolBar";


const apiBaseUrl = process.env.REACT_APP_API_URL;

function MyStudies() {
    const [userData, setUserData] = useState(null);
    const [studies, setStudies] = useState([]);
    const [newStudyNames, setNewStudyNames] = useState({});
    
    // State for managing error and info messages per study
    const [studyMessages, setStudyMessages] = useState({});
    
    // State for the confirmation modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [studyToDelete, setStudyToDelete] = useState(null);
    const messageTimers = useRef({});
  
    // New state to hold study data
    const [selectedStudyId, setSelectedStudyId] = useState(null);
    const [studyData, setStudyData] = useState([]);
  
    useEffect(() => {
      // Fetch user details and studies
      axios
        .get(`${apiBaseUrl}/user/details/`, {
          headers: {
            'X-CSRFToken': getCSRFToken(),
          },
          withCredentials: true,
        })
        .then((response) => setUserData(response.data))
        .catch((error) => console.error('Error fetching user data:', error));
  
      axios
        .get(`${apiBaseUrl}/user/studies/`, {
          headers: {
            'X-CSRFToken': getCSRFToken(),
          },
          withCredentials: true,
        })
        .then((response) => setStudies(response.data.studies))
        .catch((error) => console.error('Error fetching studies:', error));

        return () => {
            Object.values(messageTimers.current).forEach(clearTimeout);
          };
        }, []);


    // Function to set messages for a specific study
    const setMessageForStudy = (studyId, type, message) => {
        // Set the message
        setStudyMessages((prevMessages) => ({
          ...prevMessages,
          [studyId]: {
            ...prevMessages[studyId],
            [type]: message,
          },
        }));
 
        // Clear any existing timer for this study
        if (messageTimers.current[studyId]) {
          clearTimeout(messageTimers.current[studyId]);
        }
 
        // Set a new timer to clear the message after 3 seconds (3000 ms)
        messageTimers.current[studyId] = setTimeout(() => {
          setStudyMessages((prevMessages) => ({
            ...prevMessages,
            [studyId]: {
              ...prevMessages[studyId],
              [type]: '',
            },
          }));
          // Remove the timer from the ref object
          delete messageTimers.current[studyId];
        }, 3000);
      };


    // Function to open the confirmation modal
    const confirmDeleteStudy = (studyId) => {
        const study = studies.find((s) => s.id === studyId);
        setStudyToDelete(study);
        setIsModalOpen(true);
      };
    
      // Function to handle the actual deletion
      const handleDeleteStudy = () => {
        if (!studyToDelete) return;
    
        axios
          .delete(`${apiBaseUrl}/studies/${studyToDelete.id}/`, {
            headers: {
              'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
          })
          .then(() => {
            setStudies((prevStudies) =>
              prevStudies.filter((study) => study.id !== studyToDelete.id)
            );
            setIsModalOpen(false);
            setStudyToDelete(null);
          })
          .catch((error) => console.error('Error deleting study:', error));
      };

    const togglePublic = (studyId, currentStatus) => {
        axios
          .patch(
            `${apiBaseUrl}/studies/${studyId}/`,
            { public: !currentStatus },
            {
              headers: {
                'X-CSRFToken': getCSRFToken(),
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            // Update the studies state after toggling
            setStudies((prevStudies) =>
              prevStudies.map((study) =>
                study.id === studyId ? response.data : study
              )
            );
            // Clear any previous error message
            setMessageForStudy(studyId, 'error', '');
            setMessageForStudy(studyId, 'info', 'Study visibility updated.');
          })
          .catch((error) => {
            const errorMsg =
              error.response && error.response.data
                ? error.response.data.error
                : 'Error updating study.';
            setMessageForStudy(studyId, 'error', errorMsg);
          });
      };

  // Handle name change input
  const handleNameChange = (studyId, newName) => {
    setNewStudyNames((prev) => ({ ...prev, [studyId]: newName }));
  };

  // Update study name
  const updateStudyName = (studyId) => {
    const newName = newStudyNames[studyId];

    if (!newName) {
      setMessageForStudy(studyId, 'error', 'Study name cannot be empty.');
      return;
    }

    axios
      .patch(
        `${apiBaseUrl}/studies/${studyId}/`,
        { name: newName },
        {
          headers: {
            'X-CSRFToken': getCSRFToken(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        // Update the studies state after renaming
        setStudies((prevStudies) =>
          prevStudies.map((study) =>
            study.id === studyId ? response.data : study
          )
        );
        // Clear the input field
        setNewStudyNames((prev) => ({ ...prev, [studyId]: '' }));
        // Set info message
        setMessageForStudy(studyId, 'info', 'Study name updated successfully. Please log out and log back in to see the changes take effect.');
      })
      .catch((error) => {
        const errorMsg =
          error.response && error.response.data
            ? error.response.data.error
            : 'Error renaming study.';
        setMessageForStudy(studyId, 'error', errorMsg);
      });
  };

  // Function to fetch study data
  const fetchStudyData = (studyId) => {
    axios
      .get(`${apiBaseUrl}/studies/${studyId}/data/`, {
        headers: {
          'X-CSRFToken': getCSRFToken(),
        },
        withCredentials: true,
      })
      .then((response) => {
        // Check if `rows` is defined
        console.log('response.data', response.data);
        console.log('response.data.rows', response.data.rows);
        if (response.data && response.data.rows) {
          setStudyData(response.data.rows);
        } else {
          // Handle case where `rows` is undefined
          setStudyData([]);
          setMessageForStudy(studyId, 'error', 'No data available for this study.');
        }
      })
      .catch((error) => {
        console.error('Error fetching study data:', error);
        setStudyData([]);
        setMessageForStudy(studyId, 'error', 'Unable to fetch study data.');
      });
  };

  // Build columns for DataGrid
  const columnInfo = buildDataGlobalColumnInfo(studyData);
  const visibleColumnsInfo = columnInfo.filter(col => col.visible);

  const columns = visibleColumnsInfo.map((colInfo) => {
    const column = {
      field: colInfo.name,
      width: colInfo.width,
      headerName: colInfo.name.replace(/_/g, ' '),
      type: colInfo.type,
    };

    // Special handling for 'id' field
    if (colInfo.name === 'id') {
      column.renderCell = (params) => {
        let noCommaNumber = parseFloat(params.value).toFixed(0);
        return noCommaNumber;
      };
    }

    // Add valueOptions for categorical columns
    if (colInfo.type == 'singleSelect' && colInfo.valueOptions.length > 0) {
      column.valueOptions = colInfo.valueOptions;
    }

    return column;
  });

  return (
    <div className="container mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
      
      {userData ? (
        <div>

          <h3 className="text-xl font-semibold mb-4">My Studies</h3>

          {studies.length > 0 ? (
            <div className="space-y-4">
              {studies.map((study) => (
                <div key={study.id} className="p-4 bg-gray-100 border rounded-md shadow-sm">
                  <div className="flex justify-between items-center mb-2">
                    <strong className="text-lg">{study.name}</strong>
                    <span
                      className={`text-sm font-medium ${
                        study.public ? 'text-green' : 'text-red-900'
                      }`}
                    >
                      {study.public ? 'Public' : 'Private'}
                    </span>
                  </div>
                  <div className="flex flex-wrap items-center space-x-2 ">
                    <input
                      type="text"
                      placeholder="New Study Name"
                      value={newStudyNames[study.id] || ''}
                      onChange={(e) => handleNameChange(study.id, e.target.value)}
                      className="flex-1 px-4 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-green"
                    />
                    <button
                      onClick={() => updateStudyName(study.id)}
                      className="bg-dark-blue/80 hover:bg-dark-blue/90 active:bg-dark-blue text-white px-4 py-1 rounded-md"
                    >
                      Rename Study
                    </button>
                    <button
                      onClick={() => togglePublic(study.id, study.public)}
                      className={`px-4 py-1 rounded-md text-white ${
                        study.public
                          ? 'bg-teal-green/80 hover:bg-teal-green/90 active:bg-teal-green'
                          : 'bg-kelly-green/80 hover:bg-kelly-green/90 active:bg-kelly-green'
                      }`}
                    >
                      {study.public ? 'Make Private' : 'Make Public'}
                    </button>
                    <button
                      onClick={() => confirmDeleteStudy(study.id)}
                      className="bg-red-900/80 hover:bg-red-900/90 active:bg-red-900 text-white px-4 py-1 rounded-md"
                    >
                      Delete Study
                    </button>
                    {/* <button
                      onClick={() => {
                        setSelectedStudyId(study.id);
                        fetchStudyData(study.id);
                      }}
                      className="bg-dark-blue/80 hover:bg-dark-blue/90 active:bg-dark-blue text-white px-4 py-1 rounded-md"
                    >
                      View Data
                    </button> */}

                    <button
                      onClick={() => {
                        if (selectedStudyId === study.id) {
                          // Hide data
                          setSelectedStudyId(null);
                          setStudyData([]);
                        } else {
                          // View data
                          setSelectedStudyId(study.id);
                          fetchStudyData(study.id);
                        }
                      }}
                      className="bg-dark-blue/80 hover:bg-dark-blue/90 active:bg-dark-blue text-white px-4 py-1 rounded-md"
                    >
                      {selectedStudyId === study.id ? 'Hide Data' : 'View Data'}
                    </button>


                  </div>

                  {studyMessages[study.id]?.error && (
                    <p className="text-red-900 mt-2" aria-live="polite">
                      {studyMessages[study.id].error}
                    </p>
                  )}
                  {studyMessages[study.id]?.info && (
                    <p className="text-green mt-2" aria-live="polite">
                      {studyMessages[study.id].info}
                    </p>
                  )}

                  {selectedStudyId === study.id && (
                  <div className="mt-4">
                      <DataGridPro
                        pagination
                        slots={{ toolbar: CustomToolbar }}
                        density="compact"
                        rows={studyData}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                          },
                          pinnedColumns: {
                            left: ["id"],
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                      />
                    </div>
                                    )}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-teal-green">You have no studies.</p>
          )}
        </div>
      ) : (
        <p className="text-teal-green">Loading...</p>
      )}

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete the study "${studyToDelete?.name}"? This action cannot be undone.`}
        onConfirm={handleDeleteStudy}
        onCancel={() => {
          setIsModalOpen(false);
          setStudyToDelete(null);
        }}
      />
    </div>
  );
}

export default MyStudies;



