import { Container } from './Container'
import { FaGithub, FaLinkedin, FaGoogleScholar, FaXTwitter } from 'react-icons/fa6'

import jesse from './images/team/jesse.jpg'
import clayton from './images/team/clayton2.png'
import ioannis from './images/team/ioannis.jpg'

const teamMembers = [
  {
    name: 'Jesse Brown',
    role: 'CEO & Founder',
    image: jesse,
    bio: 'Jesse is a neuro + data scientist. Before starting Radiata, he was an Associate Professor in the Memory and Aging Center at UCSF. He has done extensive research on systems neuroimaging in health and disease, and built popular academic software. He has a Ph.D. in Neuroscience from UCLA.',
    linkedin: 'https://www.linkedin.com/in/jesse-brown-a9058b7/',
    x: 'https://x.com/jesseaaronbrown',
    github: 'https://github.com/jbrown81',
    googleScholar: 'https://scholar.google.com/citations?user=XAcsz0QAAAAJ',
  },
  {
    name: 'Clayton Young',
    role: 'CTO & Cofounder',
    image: clayton,
    bio: 'Clayton is a data engineer. He has experience as a data scientist, clinical research coordinator, and psychometrist. He has a BS in Psychology from UC Davis.',
    linkedin: 'https://www.linkedin.com/in/johnclaytonyoung/',
    github: 'https://github.com/ClaytonYoung',
  },
  {
    name: 'Ioannis Pappas',
    role: 'Advisor',
    image: ioannis,
    bio: 'Ioannis is a Research Assistant Professor at USC. His lab in the Laboratory of NeuroImaging uses multimodal neuroimaging tools (MRI, PET, spectroscopy) to understand pathological changes in Alzheimer’s disease. He has a Ph.D. in Neuroscience from University of Cambridge.',
    linkedin: 'https://www.linkedin.com/in/ioannis-pappas-01a439107/',
    x: 'https://x.com/JohnPappas10',
    googleScholar: 'https://scholar.google.com/citations?user=M-zFg4kAAAAJ',
  },
]

export function Team() {
  return (
    <section id="team" className="py-14">
      <Container className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-xl">
          <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Team Radiata
          </h3>
          <p className="text-gray-600 mt-3 text-xl">
            We are scientists, engineers, and developers. Our mission is to improve brain structural and functional health. Our brain biomarker engine is designed to discover brain imaging biomarkers for neurological and psychiatric disorders. We apply our expertise in human systems neuroscience, AI + ML, and clinical science. Radiata was part of Berkeley Skydeck Batch 17.
          </p>
        </div>
        <div className="mt-12">
          <ul className="list-none grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
            {teamMembers.map((member, index) => (
              <li key={index}>
                <div className="w-full">
                <img
                    src={member.image}
                    className="w-full h-auto shadow-md rounded-xl"
                    alt={member.name}
                />
                </div>
                <div className="mt-4">
                  <h4 className="text-2xl text-gray-700 font-semibold">
                    {member.name}
                  </h4>
                  <p className="text-indigo-600 text-xl">{member.role}</p>
                  <p className="text-gray-600 mt-2 text-xl">{member.bio}</p>
                  <div className="mt-3 flex gap-4 text-gray-400">
                    {member.linkedin && (
                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="visited:text-gray-400">
                        <FaLinkedin className="w-5 h-5 duration-150 hover:text-gray-500" />
                        </a>
                    )}
                    {member.github && (
                        <a href={member.github} target="_blank" rel="noopener noreferrer" className="visited:text-gray-400">
                        <FaGithub className="w-5 h-5 duration-150 hover:text-gray-500" />
                        </a>
                    )}
                    {member.googleScholar && (
                        <a href={member.googleScholar} target="_blank" rel="noopener noreferrer" className="visited:text-gray-400">
                        <FaGoogleScholar className="w-5 h-5 duration-150 hover:text-gray-500" />
                        </a>
                    )}
                    {member.x && (
                        <a href={member.x} target="_blank" rel="noopener noreferrer" className="visited:text-gray-400">
                        <FaXTwitter className="w-5 h-5 duration-150 hover:text-gray-500" />
                        </a>
                    )}
                    </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}
