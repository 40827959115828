import { useState, useEffect } from 'react'
import { AuthChangeRedirector, AnonymousRoute, AuthenticatedRoute } from './auth'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import Dashboard from './Dashboard'
import Login from './account/Login'
import RequestLoginCode from './account/RequestLoginCode'
import ConfirmLoginCode from './account/ConfirmLoginCode'
import Logout from './account/Logout'
import Signup from './account/Signup'
import ProviderSignup from './socialaccount/ProviderSignup'
import ProviderCallback from './socialaccount/ProviderCallback'
import Home from './Home'
import ChangeEmail from './account/ChangeEmail'
import ManageProviders from './socialaccount/ManageProviders'
import VerifyEmail, { loader as verifyEmailLoader } from './account/VerifyEmail'
import VerificationEmailSent from './account/VerificationEmailSent'
import RequestPasswordReset from './account/RequestPasswordReset'
import ChangePassword from './account/ChangePassword'
import MFAOverview, { loader as mfaOverviewLoader } from './mfa/MFAOverview'
import ActivateTOTP, { loader as activateTOTPLoader } from './mfa/ActivateTOTP'
import DeactivateTOTP from './mfa/DeactivateTOTP'
import RecoveryCodes, { loader as recoveryCodesLoader } from './mfa/RecoveryCodes'
import GenerateRecoveryCodes, { loader as generateRecoveryCodesLoader } from './mfa/GenerateRecoveryCodes'
import ResetPassword, { loader as resetPasswordLoader } from './account/ResetPassword'
import MFAAuthenticate from './mfa/MFAAuthenticate'
import MFAReauthenticate from './mfa/MFAReauthenticate'
import Reauthenticate from './account/Reauthenticate'
import Sessions from './usersessions/Sessions'
import Root from './Root'
import UploadForm from './uploadDirectory'
import App from './App'
//import MyData from './MyStudies'
import MyStudies from './MyStudies'
import { AuthContextProvider } from "./auth/AuthContext";
import About from './About';
import PublicStudies from './PublicStudies'

function createRouter () {
  return createBrowserRouter([
    {
      path: '/',
      // element: <AuthChangeRedirector><Root /><App /></AuthChangeRedirector>,
      element: <div className="flex flex-col h-full"><AuthChangeRedirector><Root /><div className="flex-1 min-h-0"><App /></div></AuthChangeRedirector></div>,
      children: [
        {
          path: '/account/login',
          element: <AnonymousRoute><Login /></AnonymousRoute>
        },
        {
          path: '/account/login/code',
          element: <AnonymousRoute><RequestLoginCode /></AnonymousRoute>
        },
        {
          path: '/account/login/code/confirm',
          element: <AnonymousRoute><ConfirmLoginCode /></AnonymousRoute>
        },
        {
          path: '/account/provider/callback',
          element: <ProviderCallback />
        },
        {
          path: '/account/provider/signup',
          element: <AnonymousRoute><ProviderSignup /></AnonymousRoute>
        },
        {
          path: '/account/providers',
          element: <AuthenticatedRoute><ManageProviders /></AuthenticatedRoute>
        },
        {
          path: '/account/signup',
          element: <AnonymousRoute><Signup /></AnonymousRoute>
        },
        {
          path: '/account/verify-email',
          element: <VerificationEmailSent />
        },
        {
          path: '/account/verify-email/:key',
          element: <VerifyEmail />,
          loader: verifyEmailLoader
        },
        {
          path: '/account/password/reset',
          element: <AnonymousRoute><RequestPasswordReset /></AnonymousRoute>
        },
        {
          path: '/account/password/reset/key/:key',
          element: <AnonymousRoute><ResetPassword /></AnonymousRoute>,
          loader: resetPasswordLoader
        },
        {
          path: '/account/2fa',
          element: <AuthenticatedRoute><MFAOverview /></AuthenticatedRoute>,
          loader: mfaOverviewLoader
        },
        {
          path: '/account/reauthenticate',
          element: <AuthenticatedRoute><Reauthenticate /></AuthenticatedRoute>
        },
        {
          path: '/account/2fa/reauthenticate',
          element: <AuthenticatedRoute><MFAReauthenticate /></AuthenticatedRoute>
        },
        {
          path: '/account/2fa/authenticate',
          element: <AnonymousRoute><MFAAuthenticate /></AnonymousRoute>
        },
        {
          path: '/account/2fa/totp/activate',
          element: <AuthenticatedRoute><ActivateTOTP /></AuthenticatedRoute>,
          loader: activateTOTPLoader
        },
        {
          path: '/account/2fa/totp/deactivate',
          element: <AuthenticatedRoute><DeactivateTOTP /></AuthenticatedRoute>
        },
        {
          path: '/account/2fa/recovery-codes',
          element: <AuthenticatedRoute><RecoveryCodes /></AuthenticatedRoute>,
          loader: recoveryCodesLoader
        },
        {
          path: '/account/2fa/recovery-codes/generate',
          element: <AuthenticatedRoute><GenerateRecoveryCodes /></AuthenticatedRoute>,
          loader: generateRecoveryCodesLoader
        },

      ]
    },
    {
      path: '/dashboard',
      element:<AuthenticatedRoute><Dashboard /></AuthenticatedRoute>,
      children: [
        {
          path: '/dashboard/account/logout',
          element: <Logout />
        },
      ]
    },

    {
      path: '/',
      element: <AuthChangeRedirector><Root /></AuthChangeRedirector>,
      children: [
      {
        path: '/account/email',
        element:  <AuthenticatedRoute><ChangeEmail /></AuthenticatedRoute>
      },
      {
        path: '/account/password/change',
        element: <AuthenticatedRoute><ChangePassword /></AuthenticatedRoute>
      },
    //   {
    //     path: '/account/myaccount/change-password',
    //     element: <AuthenticatedRoute><ChangePassword /></AuthenticatedRoute>
    // },
    {
      path: '/about',
      element: <About />
      },
    {
      path: '/account/mydata/my-studies',
      element: <AuthenticatedRoute><MyStudies /></AuthenticatedRoute>
    },
    {
      path: '/account/mydata/upload',
      element: <AuthenticatedRoute><UploadForm /></AuthenticatedRoute>
    },
    {
      path: 'public-studies',
      element: <PublicStudies />
    },
    {
      path: '/account/mydata/public-studies',
      element: <AuthenticatedRoute><PublicStudies /></AuthenticatedRoute>
    },
    ]
  }
  ])
}

export default function Router () {
  // If we create the router globally, the loaders of the routes already trigger
  // even before the <AuthContext/> trigger the initial loading of the auth.
  // state.
  const [router, setRouter] = useState(null)
  useEffect(() => {
    setRouter(createRouter())
  }, [])
  return router ? <RouterProvider router={router} /> : null
}
